// src/pages/InteractivePortfolio.tsx
import React, { useState } from 'react';
import DraggableItem from '../components/ui/DraggableItem';
import NavCircle from '../components/ui/NavCircle'; // Import the NavCircle component

const initialItems = [
  { id: 'item1', content: 'Education', info: 'BS in Computer Science from XYZ University' },
  { id: 'item2', content: 'Skills', info: 'JavaScript, React, Node.js, Python' },
  { id: 'item3', content: 'Experience', info: '5 years as a Full Stack Developer' },
  { id: 'item4', content: 'Projects', info: 'Built an e-commerce platform, Created a chat application' },
];

interface Item {
  id: string;
  content: string;
  info: string;
  position?: string;
}

const InteractivePortfolio: React.FC = () => {
  const [items, setItems] = useState(initialItems.map((item, index) => ({
    ...item,
    position: index < 2 ? 'left' : 'right'
  })));
  const [centerItem, setCenterItem] = useState<Item | null>(null);

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, item: Item) => {
    e.dataTransfer.setData('text/plain', item.id);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>, targetArea: string) => {
    e.preventDefault();
    const itemId = e.dataTransfer.getData('text');
    const draggedItem = items.find(item => item.id === itemId);
    
    if (targetArea === 'center') {
      if (centerItem) {
        setItems(prevItems => prevItems.map(item => 
          item.id === centerItem?.id ? { ...item, position: centerItem.position || 'left' } : item
        ));
      }
      if (draggedItem) {
        setCenterItem({ ...draggedItem, position: draggedItem.position });
      }
      setItems(prevItems => prevItems.map(item => 
        item.id === itemId ? { ...item, position: 'center' } : item
      ));
    } else {
      setItems(prevItems => prevItems.map(item => 
        item.id === itemId ? { ...item, position: targetArea } : item
      ));
      if (centerItem && centerItem.id === itemId) {
        setCenterItem(null);
      }
    }
  };

  const leftItems = items.filter(item => item.position === 'left');
  const rightItems = items.filter(item => item.position === 'right');

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <div className="flex-grow flex justify-between p-6 bg-yellow-50">
        <div 
          className="w-1/4 p-4 bg-yellow-100 rounded-lg shadow-lg"
          onDragOver={onDragOver}
          onDrop={(e) => onDrop(e, 'left')}
        >
          {leftItems.map((item) => (
            <DraggableItem key={item.id} item={item} onDragStart={onDragStart} />
          ))}
        </div>

        <div 
          className="w-1/2 p-6 bg-yellow-200 rounded-lg shadow-lg flex items-center justify-center"
          onDragOver={onDragOver}
          onDrop={(e) => onDrop(e, 'center')}
        >
          {centerItem ? (
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold mb-2">{centerItem.content}</h2>
              <p>{centerItem.info}</p>
            </div>
          ) : (
            <p className="text-center text-gray-500">Drag an item here to view details</p>
          )}
        </div>

        <div 
          className="w-1/4 p-4 bg-yellow-100 rounded-lg shadow-lg"
          onDragOver={onDragOver}
          onDrop={(e) => onDrop(e, 'right')}
        >
          {rightItems.map((item) => (
            <DraggableItem key={item.id} item={item} onDragStart={onDragStart} />
          ))}
        </div>
      </div>

      {/* Add the NavCircle at the bottom */}
      <NavCircle />
    </div>
  );
};

export default InteractivePortfolio;
