import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { RadioGroup, RadioGroupItem } from '../components/ui/radio-group';
import { Label } from '../components/ui/label';
import { Alert, AlertTitle, AlertDescription } from '../components/ui/alert';
import { Timer, AlertCircle } from 'lucide-react';
import Input from '../components/ui/input';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
  } from "../components/ui/select";

// Update this to your API URL
const API_URL = 'https://pretoeic.kantinanplayground.com';

const PostTest = () => {
  const [questions] = useState([
    {
      "question": "The local farmers sell their produce at the weekly __________.",
      "answer": "market",
      "options": ["market", "expense", "profit", "revenue"]
    },
    {
      "question": "A sudden ________ was held to address urgent issues.",
      "answer": "meeting",
      "options": ["meeting", "cubicle", "manager", "workload"]
    },
    {
      "question": "The _______ is essential for planning efficient travel routes.",
      "answer": "itinerary",
      "options": ["itinerary", "customs", "passport", "guidebook"]
    },
    {
      "question": "Without considering the _______, it may be challenging to reach an accurate conclusion.",
      "answer": "symptom",
      "options": ["symptom", "treatment", "prescription", "diagnosis"]
    },
    {
      "question": "The _______ scanned the items quickly.",
      "answer": "customer",
      "options": ["customer", "checkout", "product", "cashier"]
    },
    {
      "question": "An ________ can be understood as a set of rules to be followed in calculations or problem-solving operations.",
      "answer": "algorithm",
      "options": ["algorithm", "server", "firewall", "network"]
    },
    {
      "question": "The new _____ comes fully furnished and is located in the heart of downtown.",
      "answer": "apartment",
      "options": ["apartment", "furniture", "contract", "realtor"]
    },
    {
      "question": "Our company's hiring _______ has been very successful this year.",
      "answer": "recruiter",
      "options": ["recruiter", "benefit", "interview", "career"]
    },
    {
      "question": "The strength of a _______ can significantly impact a business's market position.",
      "answer": "brand",
      "options": ["brand", "target", "advertising", "consumer"]
    },
    {
      "question": "During the latest tour, we visited the largest ____ in the district.",
      "answer": "factory",
      "options": ["factory", "equipment", "shift", "inventory"]
    }
  ]);

  const [selectedAnswers, setSelectedAnswers] = useState<{ [key: number]: string }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(7 * 60 + 30);
  const [error, setError] = useState<string | null>(null);
  const [shuffledQuestions, setShuffledQuestions] = useState<{ question: string; answer: string; options: string[] }[]>([]);
  const [preTestNumber, setPreTestNumber] = useState('');
  const [isValidating, setIsValidating] = useState(false);
  const [hintEnabled, setHintEnabled] = useState<boolean | null>(null);


  useEffect(() => {
    const shuffled = [...questions].map(q => ({
      ...q,
      options: shuffleArray(q.options)
    }));
    setShuffledQuestions(shuffled);
  }, []);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (hasStarted && !isCompleted && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            handleSubmit(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [hasStarted, isCompleted]);

  const shuffleArray = (array: string[]) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const validatePreTestNumber = async () => {
    if (!preTestNumber.trim()) {
      setError('Please enter your pre-test number');
      return false;
    }

    try {
      console.log('Validating number:', `${API_URL}/api/validate-pretest/${preTestNumber}`);
      
      const response = await fetch(`${API_URL}/api/validate-pretest/${preTestNumber}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });

      const data = await response.json();
      
      if (!response.ok || !data.valid) {
        throw new Error(data.error || 'Invalid pre-test number');
      }

      return true;
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Failed to validate pre-test number');
      }
      return false;
    }
  };


  const handleStart = async () => {
    setIsValidating(true);
    setError(null);

    try {
      const isValid = await validatePreTestNumber();
      if (isValid) {
        setHasStarted(true);
      }
    } catch (error) {
      console.error('Validation error:', error);
      setError('Failed to validate pre-test number. Please try again.');
    } finally {
      setIsValidating(false);
    }
  };


  const handleAnswerChange = (questionIndex: number, value: string) => {
    if (isCompleted) return;

    setSelectedAnswers(prev => ({
      ...prev,
      [questionIndex]: value
    }));
  };

  const handleSubmit = async (isTimeout = false) => {
    if (!isTimeout && Object.keys(selectedAnswers).length !== shuffledQuestions.length) {
      setError('Please answer all questions before submitting.');
      return;
    }
  
    setIsSubmitting(true);
    setError(null);
  
    try {
      // Log the data being sent to verify it
      console.log('Submitting:', {
        answers: selectedAnswers,
        timeRemaining,
        isTimeout,
        preTestNumber: parseInt(preTestNumber),
        hintEnabled: hintEnabled === true // Explicitly convert to boolean
      });
  
      const response = await fetch(`${API_URL}/api/post-test-completion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          answers: selectedAnswers,
          timeRemaining,
          isTimeout,
          preTestNumber: parseInt(preTestNumber),
          hintEnabled: hintEnabled === true // Explicitly convert to boolean
        }),
      });
  
      const data = await response.json();
      setIsCompleted(true);
  
    } catch (err) {
      setError('Failed to submit quiz');
    } finally {
      setIsSubmitting(false);
    }
  };
  // Start screen with pre-test number input
  if (!hasStarted) {
    return (
      <Card className="w-full max-w-3xl mx-auto mb-8">
        <CardHeader>
          <CardTitle>Post-Test Quiz</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Instructions</h2>
            <ul className="list-disc pl-6 space-y-2">
              <li>Enter your pre-test number to begin</li>
              <li>You will have 7 minutes and 30 seconds to complete this quiz</li>
              <li>You must answer all questions before submitting</li>
              <li>Your answers will be automatically submitted when time runs out</li>
              <li>Select whether you want hints enabled or disabled for this test</li>
            </ul>
          </div>
          
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="preTestNumber">Pre-Test Number</Label>
              <Input
                id="preTestNumber"
                type="number"
                placeholder="Enter your pre-test number"
                value={preTestNumber}
                onChange={(e) => setPreTestNumber(e.target.value)}
                className="w-full"
              />
            </div>
            
            <div className="space-y-2 relative">
              <Label>Assigned Group</Label>
              <Select onValueChange={(value) => setHintEnabled(value === 'true')}>
                <SelectTrigger className="w-full bg-white">
                  <SelectValue placeholder="Select hint preference" />
                </SelectTrigger>
                <SelectContent 
                  position="item-aligned" 
                  className="w-[--radix-select-trigger-width] bg-white"
                  align="center"
                >
                  <SelectGroup>
                    <SelectItem value="true">With Hints</SelectItem>
                    <SelectItem value="false">No Hints</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            
            {error && (
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            
            <Button 
              onClick={handleStart} 
              className="w-full"
              disabled={isValidating || hintEnabled === null || !preTestNumber}
            >
              {isValidating ? 'Validating...' : 'Start Quiz'}
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  // Quiz screen
  return (
    <Card className="w-full max-w-3xl mx-auto mb-8">
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle>Post-Test Quiz</CardTitle>
          <div className="flex items-center gap-2 text-lg font-semibold">
            <Timer className="w-5 h-5" />
            <span className={timeRemaining <= 30 ? "text-red-500 animate-pulse" : ""}>
              {formatTime(timeRemaining)}
            </span>
          </div>
        </div>
      </CardHeader>

      {error && (
        <div className="px-6 mb-4">
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        </div>
      )}

      {isCompleted && (
        <div className="px-6">
          <Alert>
            <AlertTitle>Quiz Completed!</AlertTitle>
            <AlertDescription>
              <p>Thank you for completing both tests!</p>
              {timeRemaining === 0 && (
                <p className="text-sm text-red-500">Time limit reached</p>
              )}
            </AlertDescription>
          </Alert>
        </div>
      )}

      <CardContent className={`space-y-8 ${isCompleted ? 'opacity-50' : ''}`}>
        {shuffledQuestions.map((question, index) => (
          <div key={index} className="space-y-4">
            <h3 className="text-lg font-medium">
              {index + 1}. {question.question}
            </h3>
            <RadioGroup
              onValueChange={(value) => handleAnswerChange(index, value)}
              className="space-y-2"
              disabled={isCompleted}
              value={selectedAnswers[index] || ""}
            >
              {question.options.map((option, optionIndex) => (
                <div key={optionIndex} className="flex items-center space-x-2">
                  <RadioGroupItem 
                    value={option} 
                    id={`q${index}-${optionIndex}`}
                    disabled={isCompleted}
                  />
                  <Label htmlFor={`q${index}-${optionIndex}`}>{option}</Label>
                </div>
              ))}
            </RadioGroup>
          </div>
        ))}
      </CardContent>

      <CardFooter>
        <Button 
          className="w-full"
          onClick={() => handleSubmit(false)} 
          disabled={isSubmitting || isCompleted}
        >
          {isSubmitting ? 'Submitting...' : isCompleted ? 'Quiz Completed' : 'Submit Quiz'}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default PostTest;