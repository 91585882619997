import React from 'react';
import classNames from 'classnames';

type ButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  variant?: 'default' | 'ghost' | 'custom' | 'outline';
};

export const Button: React.FC<ButtonProps> = ({ 
  children, 
  onClick, 
  className, 
  disabled,
  style,
  variant = 'default'
}) => {
  const baseClasses = 'px-4 py-2 rounded focus:outline-none transition-colors duration-200';
  
  const variantClasses = {
    default: {
      'bg-gray-300 cursor-not-allowed': disabled,
      'bg-blue-500 hover:bg-blue-600 text-white': !disabled && variant === 'default',
    },
    ghost: {
      'opacity-50 cursor-not-allowed': disabled,
      'hover:bg-opacity-90': !disabled,
    },
    custom: {
      'opacity-50 cursor-not-allowed': disabled,
    },
    outline: {
      'border border-blue-500 text-blue-500 cursor-not-allowed': disabled,
      'border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white': !disabled,
    }
  };

  
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={style}
      className={classNames(
        baseClasses,
        variantClasses[variant],
        className
      )}
    >
      {children}
    </button>
  );
};