// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/home';
import NewWordGuesser from './pages/NewWordGuesser';
import ExpGuesser from './pages/ExperimentGuesser';
import ExpGuesserNoHint from './pages/ExperimentGuesserNoHint';
import PreTOEIC from './pages/PreTest';
import GroupD from './pages/Groupdashboard';
import PostTOEIC from './pages/PostTest';
import Analyzer from './pages/Analyzer';

import Densu from './pages/DensuData';
import DensuMock from './pages/DensuMock';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/densu" element={<Densu />} />
        <Route path="/densu-mock" element={<DensuMock />} />
        <Route path="/WordGuesser" element={<NewWordGuesser />} />
        <Route path="/ExpGuesser" element={<ExpGuesser />} />
        <Route path="/ExpGuesserNoHint" element={<ExpGuesserNoHint />} />
        <Route path="/Pretoeic" element={<PreTOEIC />} />
        <Route path="/GroupDashboard" element={<GroupD />} />
        <Route path="/Posttoeic" element={<PostTOEIC />} />
        <Route path="/Analyzer" element={<Analyzer />} />
        
      </Routes>
    </Router>
  );
};

export default App;
