import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { RadioGroup, RadioGroupItem } from '../components/ui/radio-group';
import { Label } from '../components/ui/label';
import { Alert, AlertTitle, AlertDescription } from '../components/ui/alert';
import { Timer, AlertCircle } from 'lucide-react';

// Update this to your Railway backend URL
const API_URL = 'https://pretoeic.kantinanplayground.com';

const Quiz = () => {
  const [questions] = useState([
    {
      "question": "The local farmers sell their produce at the weekly __________.",
      "answer": "market",
      "options": ["market", "expense", "profit", "revenue"]
    },
    {
      "question": "A sudden ________ was held to address urgent issues.",
      "answer": "meeting",
      "options": ["meeting", "cubicle", "manager", "workload"]
    },
    {
      "question": "The _______ is essential for planning efficient travel routes.",
      "answer": "itinerary",
      "options": ["itinerary", "customs", "passport", "guidebook"]
    },
    {
      "question": "Without considering the _______, it may be challenging to reach an accurate conclusion.",
      "answer": "symptom",
      "options": ["symptom", "treatment", "prescription", "diagnosis"]
    },
    {
      "question": "The _______ scanned the items quickly.",
      "answer": "customer",
      "options": ["customer", "checkout", "product", "cashier"]
    },
    {
      "question": "An ________ can be understood as a set of rules to be followed in calculations or problem-solving operations.",
      "answer": "algorithm",
      "options": ["algorithm", "server", "firewall", "network"]
    },
    {
      "question": "The new _____ comes fully furnished and is located in the heart of downtown.",
      "answer": "apartment",
      "options": ["apartment", "furniture", "contract", "realtor"]
    },
    {
      "question": "Our company's hiring _______ has been very successful this year.",
      "answer": "recruiter",
      "options": ["recruiter", "benefit", "interview", "career"]
    },
    {
      "question": "The strength of a _______ can significantly impact a business's market position.",
      "answer": "brand",
      "options": ["brand", "target", "advertising", "consumer"]
    },
    {
      "question": "During the latest tour, we visited the largest ____ in the district.",
      "answer": "factory",
      "options": ["factory", "equipment", "shift", "inventory"]
    }
  ]);

  const [selectedAnswers, setSelectedAnswers] = useState<{ [key: number]: string }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(7 * 60 + 30);
  const [completionNumber, setCompletionNumber] = useState(null);
  const [error, setError] = useState<string | null>(null);
  const [shuffledQuestions, setShuffledQuestions] = useState<{ question: string; answer: string; options: string[] }[]>([]);

  useEffect(() => {
    const shuffled = [...questions].map(q => ({
      ...q,
      options: shuffleArray(q.options)
    }));
    setShuffledQuestions(shuffled);
  }, []);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (hasStarted && !isCompleted && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            handleSubmit(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [hasStarted, isCompleted]);

  const shuffleArray = (array: string[]) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleStart = () => {
    setHasStarted(true);
  };

  const handleAnswerChange = (questionIndex: number, value: string) => {
    if (isCompleted) return;

    setSelectedAnswers(prev => ({
      ...prev,
      [questionIndex]: value
    }));
  };

  const handleSubmit = async (isTimeout = false) => {
    if (!isTimeout && Object.keys(selectedAnswers).length !== shuffledQuestions.length) {
      setError('Please answer all questions before submitting.');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      console.log('Submitting to:', `${API_URL}/api/quiz-completion`);
      const response = await fetch(`${API_URL}/api/quiz-completion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          answers: selectedAnswers,
          timeRemaining,
          isTimeout
        }),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({ error: 'Failed to submit quiz' }));
        throw new Error(errorData.error || 'Failed to submit quiz');
      }

      const data = await response.json();
      console.log('Response data:', data);

      setCompletionNumber(data.completionNumber);
      setIsCompleted(true);
    } catch (err) {
      console.error('Submission error:', err);
      if (err instanceof Error) {
        setError(`Failed to submit: ${err.message}. Please try again or contact your instructor.`);
      } else {
        setError('Failed to submit. Please try again or contact your instructor.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  // Start screen
  if (!hasStarted) {
    return (
      <Card className="w-full max-w-3xl mx-auto mb-8">
        <CardHeader>
          <CardTitle>Pre-Test Quiz</CardTitle>
        </CardHeader>
        <CardContent className="text-center">
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Instructions</h2>
            <ul className="text-left list-disc pl-6 space-y-2">
              <li>You will have 7 minutes and 30 seconds to complete this quiz</li>
              <li>You must answer all questions before submitting</li>
              <li>Your answers will be automatically submitted when time runs out</li>
              <li>You will receive a number after completion - save it for the post-test!</li>
            </ul>
            <Button 
              onClick={handleStart} 
              className="mt-6"
            >
              Start Quiz
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  // Quiz screen
  return (
    <Card className="w-full max-w-3xl mx-auto mb-8">
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle>Pre-Test Quiz</CardTitle>
          <div className="flex items-center gap-2 text-lg font-semibold">
            <Timer className="w-5 h-5" />
            <span className={timeRemaining <= 30 ? "text-red-500 animate-pulse" : ""}>
              {formatTime(timeRemaining)}
            </span>
          </div>
        </div>
      </CardHeader>

      {error && (
        <div className="px-6 mb-4">
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        </div>
      )}

      {isCompleted && (
        <div className="px-6">
          <Alert className="mb-6">
            <AlertTitle>Quiz Completed!</AlertTitle>
            <AlertDescription>
              <div className="space-y-2">
                <p className="text-2xl font-bold">Your Number: {completionNumber}</p>
                <p className="text-sm font-medium text-yellow-600">
                  ⚠️ IMPORTANT: Take a screenshot or write down this number! 
                  You will need it for the post-test.
                </p>
                {timeRemaining === 0 && (
                  <p className="text-sm text-red-500">Time limit reached</p>
                )}
              </div>
            </AlertDescription>
          </Alert>
        </div>
      )}

      <CardContent className={`space-y-8 ${isCompleted ? 'opacity-50' : ''}`}>
        {shuffledQuestions.map((question, index) => (
          <div key={index} className="space-y-4">
            <h3 className="text-lg font-medium">
              {index + 1}. {question.question}
            </h3>
            <RadioGroup
              onValueChange={(value) => handleAnswerChange(index, value)}
              className="space-y-2"
              disabled={isCompleted}
              value={selectedAnswers[index] || ""}
            >
              {question.options.map((option, optionIndex) => (
                <div key={optionIndex} className="flex items-center space-x-2">
                  <RadioGroupItem 
                    value={option} 
                    id={`q${index}-${optionIndex}`}
                    disabled={isCompleted}
                  />
                  <Label htmlFor={`q${index}-${optionIndex}`}>{option}</Label>
                </div>
              ))}
            </RadioGroup>
          </div>
        ))}
      </CardContent>

      <CardFooter>
        <Button 
          className="w-full"
          onClick={() => handleSubmit(false)} 
          disabled={isSubmitting || isCompleted}
        >
          {isSubmitting ? 'Submitting...' : isCompleted ? 'Quiz Completed' : 'Submit Quiz'}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default Quiz;