// src/components/ui/Card.tsx
import React from 'react';

type CardProps = {
  children: React.ReactNode;
  className?: string;
};

export const CardTitle: React.FC<CardProps> = ({ children, className }) => {
  return (
    <div className={`text-center text-2xl font-semibold ${className}`}>
      {children}
    </div>
  );
};
export const Card: React.FC<CardProps> = ({ children, className }) => {
  return (
    <div className={`p-4 rounded-lg shadow-lg bg-white ${className}`}>
      {children}
    </div>
  );
};

export const CardHeader: React.FC<CardProps> = ({ children, className }) => {
  return (
    <div className={`border-b pb-2 mb-2 text-center ${className}`}>
      {children}
    </div>
  );
};

export const CardContent: React.FC<CardProps> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export const CardFooter: React.FC<CardProps> = ({ children, className }) => {
  return (
    <div className={`flex justify-between p-2 ${className}`}>
      {children}
    </div>
  );
};

// create card discrippion component
export const CardDescription: React.FC<CardProps> = ({ children, className }) => {
  return (
    <div className={`text-center text-lg ${className}`}>
      {children}
    </div>
  );
};