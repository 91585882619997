import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend, LabelList } from 'recharts';
import { Clock, Skull, Users, Monitor, Trophy, LineChart, Table, Search, X } from 'lucide-react';

interface TaskOrder {
  task_description: string;
  task_order?: number;
  frequency?: number;
  order_frequencies?: Record<number, number>;
  stage?: number;
}

interface PlayerDeathHistory {
  death_id: number;
  player_id: string;
  session_id: string;
  stage: number;
  cause_id: number;
  death_time: number;
  created_at: string;
}

const DEATH_CAUSES: Record<number, string> = {
  1: "Restart game",
  2: "Failed to turn off lights in time",
  3: "Failed to lock the door in time",
  4: "Failed to close the curtains in time",
  5: "Failed to turn off the TV in time",
  6: "Failed to call or send SMS to 191 in time",
  7: "Failed to turn off the alert in time",
  8: "Failed to turn off the radio in time",
  9: "Failed to enter the next room before the timer starts",
  10: "Walked too close to the stairs",
  11: "Ran in the room when the hunter was watching",
  12: "Failed to hide before the hunter arrived",
  13: "Failed the quick-time event (heart reaches 0)",
  14: "Failed to enter the closet before the hunter arrived",
  15: "Killed by the red area or moved into an open space (Run out from playing area)",
  16: "Time reaches 00:00 during the run"
};

const getStageNameFromNumber = (stage: number) => {
  switch (stage) {
    case 1: return 'Stage 1-1';
    case 2: return 'Stage 1-2';
    case 3: return 'Stage 2';
    case 4: return 'Stage 3';
    default: return `Stage ${stage}`;
  }
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    payload: {
      name: string;
      value: number;
      percentage: string;
      label: string;
    };
  }>;
  label?: string;
}

// Add type definitions for the processed data
interface ProcessedDataItem {
  name: string;
  value: number;
  percentage: string;
  label: string;
  color: string;
}

interface DeathStageDataItem extends ProcessedDataItem {
  stageName: string;
  stage: number;
  death_count: number;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) return null;

  const data = payload[0].payload;
  return (
    <div className="bg-white p-2 border rounded shadow">
      <p className="text-sm">{`${data.name || label}: ${data.value} (${data.percentage}%)`}</p>
    </div>
  );
};

interface ApiResponse {
  status: boolean;
  data: {
    playerDeathHistory?: PlayerDeathHistory[];
    deathRanking: Array<{
      cause_of_death?: string;
      cause_id: number;
      stage: number;
      occurrence_count: number;
    }>;
    taskOrderFrequency: Array<{
      task_description: string;
      task_order?: number;
      frequency?: number;
      order_frequencies?: Record<number, number>;
    }>;
    stageDeathRanking: Array<{
      stage: number;
      death_count: number;
    }>;
    playerDeviceCount: Array<{
      device_type: string;
      device_count: number;
    }>;
    sessionPlayerCountRanking: Array<{
      session_id: string;
      players_in_session: number;
      session_count?: number;
    }>;
    averageStageTime: Array<{
      player_amount: number;
      time_taken: Record<number, { min: number; max: number; avg: number }>;
    }>;
  };
}

const MOCK_DATA = {
  "all": {
    "status": true,
    "data": {
      "playerDeathHistory": [],
      "deathRanking": [
        {
          "cause_id": 3,
          "stage": 1,
          "occurrence_count": 245
        },
        {
          "cause_id": 3,
          "stage": 2,
          "occurrence_count": 144
        },
        {
          "cause_id": 7,
          "stage": 1,
          "occurrence_count": 198
        },
        {
          "cause_id": 8,
          "stage": 2,
          "occurrence_count": 176
        },
        {
          "cause_id": 9,
          "stage": 2,
          "occurrence_count": 154
        },
        {
          "cause_id": 9,
          "stage": 1,
          "occurrence_count": 81
        },
        {
          "cause_id": 10,
          "stage": 3,
          "occurrence_count": 132
        },
        {
          "cause_id": 11,
          "stage": 4,
          "occurrence_count": 98
        }
      ],
      "taskOrderFrequency": [
        {
          "task_description": "Lock the doors",
          "stage": 1,
          "order_frequencies": {
            "1": 245,
            "2": 123,
            "3": 45
          }
        },
        {
          "task_description": "Close the curtains",
          "stage": 1,
          "order_frequencies": {
            "1": 189,
            "2": 234,
            "3": 67
          }
        },
        {
          "task_description": "Turn off the TV",
          "stage": 1,
          "order_frequencies": {
            "2": 178,
            "3": 245,
            "4": 89
          }
        },
        {
          "task_description": "Turn off the radio",
          "stage": 1,
          "order_frequencies": {
            "2": 167,
            "3": 198,
            "4": 76
          }
        },
        {
          "task_description": "Send SMS",
          "stage": 1,
          "order_frequencies": {
            "3": 289,
            "4": 156,
            "5": 45
          }
        },
        {
          "task_description": "Lock the doors",
          "stage": 2,
          "order_frequencies": {
            "1": 321,
            "2": 225,
            "3": 445
          }
        },
        {
          "task_description": "Close the curtains",
          "stage": 2,
          "order_frequencies": {
            "1": 825,
            "2": 412,
            "3": 325
          }
        },
        {
          "task_description": "Turn off the TV",
          "stage": 2,
          "order_frequencies": {
            "2": 142,
            "3": 824,
            "4": 366
          }
        },
        {
          "task_description": "Turn off the radio",
          "stage": 2,
          "order_frequencies": {
            "2": 225,
            "3": 741,
            "4": 476
          }
        },
        {
          "task_description": "Send SMS",
          "stage": 2,
          "order_frequencies": {
            "3": 389,
            "4": 156,
            "5": 545
          }
        }
      ],
      "stageDeathRanking": [
        {
          "stage": 1,
          "death_count": 456
        },
        {
          "stage": 2,
          "death_count": 323
        },
        {
          "stage": 3,
          "death_count": 234
        },
        {
          "stage": 4,
          "death_count": 178
        }
      ],
      "playerDeviceCount": [
        {
          "device_type": "PC",
          "device_count": 221
        },
        {
          "device_type": "Mobile",
          "device_count": 50
        }
      ],
      "sessionPlayerCountRanking": [
        {
          "session_id": "session1",
          "players_in_session": 1,
          "session_count": 234
        },
        {
          "session_id": "session2",
          "players_in_session": 2,
          "session_count": 167
        },
        {
          "session_id": "session3",
          "players_in_session": 3,
          "session_count": 89
        },
        {
          "session_id": "session3",
          "players_in_session": 4,
          "session_count": 46
        }
      ],
      "averageStageTime": [
        {
          "player_amount": 1,
          "time_taken": {
            "1": {
              "min": 23,
              "max": 45,
              "avg": 34
            },
            "2": {
              "min": 16,
              "max": 55,
              "avg": 25
            },
            "3": {
              "min": 19,
              "max": 67,
              "avg": 38
            },
            "4": {
              "min": 25,
              "max": 60,
              "avg": 44
            }
          }
        },
        {
          "player_amount": 2,
          "time_taken": {
            "1": {
              "min": 18,
              "max": 66,
              "avg": 45
            },
            "2": {
              "min": 13,
              "max": 50,
              "avg": 28
            },
            "3": {
              "min": 16,
              "max": 77,
              "avg": 55
            },
            "4": {
              "min": 15,
              "max": 50,
              "avg": 32
            }
          }
        },
        {
          "player_amount": 3,
          "time_taken": {
            "1": {
              "min": 13,
              "max": 65,
              "avg": 44
            },
            "2": {
              "min": 19,
              "max": 45,
              "avg": 28
            },
            "3": {
              "min": 28,
              "max": 55,
              "avg": 32
            },
            "4": {
              "min": 35,
              "max": 70,
              "avg": 44
            }
          }
        },
        {
          "player_amount": 4,
          "time_taken": {
            "1": {
              "min": 13,
              "max": 49,
              "avg": 32
            },
            "2": {
              "min": 12,
              "max": 42,
              "avg": 22
            },
            "3": {
              "min": 11,
              "max": 44,
              "avg": 25
            },
            "4": {
              "min": 18,
              "max": 50,
              "avg": 12
            }
          }
        }
      ],
      "genderDistribution": [
        {
          "male": 456,
        },
        {
          "female": 234,
        },
        {
          "default": 78,
        }
      ]
    }
  },
  "players": {
    "CoSITest1": {
      "status": true,
      "data": {
        "playerDeathHistory": [
          {
            "death_id": 1,
            "player_id": "CoSITest1",
            "session_id": "session1",
            "stage": 2,
            "cause_id": 3,
            "death_time": 45,
            "created_at": "2024-10-25T10:30:00.000Z"
          },
          {
            "death_id": 2,
            "player_id": "CoSITest1",
            "session_id": "session2",
            "stage": 3,
            "cause_id": 7,
            "death_time": 78,
            "created_at": "2024-10-25T11:45:00.000Z"
          },
          {
            "death_id": 3,
            "player_id": "CoSITest1",
            "session_id": "session3",
            "stage": 1,
            "cause_id": 12,
            "death_time": 23,
            "created_at": "2024-10-25T14:20:00.000Z"
          }
        ],
        "deathRanking": [
          {
            "cause_id": 3,
            "occurrence_count": 1
          },
          {
            "cause_id": 7,
            "occurrence_count": 1
          },
          {
            "cause_id": 12,
            "occurrence_count": 1
          }
        ],
        "taskOrderFrequency": [
          {
            "task_description": "Lock the doors",
            "task_order": 1,
            "frequency": 2
          },
          {
            "task_description": "Close the curtains",
            "task_order": 2,
            "frequency": 1
          },
          {
            "task_description": "Turn off the TV",
            "task_order": 3,
            "frequency": 1
          }
        ],
        "stageDeathRanking": [
          {
            "stage": 1,
            "death_count": 1
          },
          {
            "stage": 2,
            "death_count": 1
          },
          {
            "stage": 3,
            "death_count": 1
          }
        ],
        "playerDeviceCount": [
          {
            "device_type": "PC",
            "device_count": 1
          }
        ],
        "sessionPlayerCountRanking": [
          {
            "session_id": "session1",
            "players_in_session": 1
          },
          {
            "session_id": "session2",
            "players_in_session": 2
          }
        ],
      }
    },
    "CoSITest2": {
      "status": true,
      "data": {
        "playerDeathHistory": [
          {
            "death_id": 4,
            "player_id": "CoSITest2",
            "session_id": "session4",
            "stage": 4,
            "cause_id": 15,
            "death_time": 120,
            "created_at": "2024-10-25T15:10:00.000Z"
          },
          {
            "death_id": 5,
            "player_id": "CoSITest2",
            "session_id": "session5",
            "stage": 2,
            "cause_id": 8,
            "death_time": 56,
            "created_at": "2024-10-25T16:30:00.000Z"
          }
        ],
        "deathRanking": [
          {
            "cause_id": 15,
            "occurrence_count": 1
          },
          {
            "cause_id": 8,
            "occurrence_count": 1
          }
        ],
        "taskOrderFrequency": [
          {
            "task_description": "Send SMS",
            "task_order": 1,
            "frequency": 2
          },
          {
            "task_description": "Turn off the radio",
            "task_order": 2,
            "frequency": 1
          },
          {
            "task_description": "Hide",
            "task_order": 3,
            "frequency": 1
          }
        ],
        "stageDeathRanking": [
          {
            "stage": 2,
            "death_count": 1
          },
          {
            "stage": 4,
            "death_count": 1
          }
        ],
        "playerDeviceCount": [
          {
            "device_type": "Mobile",
            "device_count": 1
          }
        ],
        "sessionPlayerCountRanking": [
          {
            "session_id": "session4",
            "players_in_session": 1
          },
          {
            "session_id": "session5",
            "players_in_session": 3
          }
        ],
        "averageStageTime": [
          {
            "player_amount": 1,
            "time_taken": {
              "1": {
                "min": 23,
                "max": 45,
                "avg": 34
              },
              "2": {
                "min": 16,
                "max": 55,
                "avg": 25
              },
              "3": {
                "min": 19,
                "max": 67,
                "avg": 38
              },
              "4": {
                "min": 25,
                "max": 60,
                "avg": 44
              }
            }
          },
          {
            "player_amount": 2,
            "time_taken": {
              "1": {
                "min": 18,
                "max": 66,
                "avg": 45
              },
              "2": {
                "min": 13,
                "max": 50,
                "avg": 28
              },
              "3": {
                "min": 16,
                "max": 77,
                "avg": 55
              },
              "4": {
                "min": 15,
                "max": 50,
                "avg": 32
              }
            }
          },
          {
            "player_amount": 3,
            "time_taken": {
              "1": {
                "min": 13,
                "max": 65,
                "avg": 44
              },
              "2": {
                "min": 19,
                "max": 45,
                "avg": 28
              },
              "3": {
                "min": 28,
                "max": 55,
                "avg": 32
              },
              "4": {
                "min": 35,
                "max": 70,
                "avg": 44
              }
            }
          },
          {
            "player_amount": 4,
            "time_taken": {
              "1": {
                "min": 13,
                "max": 49,
                "avg": 32
              },
              "2": {
                "min": 12,
                "max": 42,
                "avg": 22
              },
              "3": {
                "min": 11,
                "max": 44,
                "avg": 25
              },
              "4": {
                "min": 18,
                "max": 50,
                "avg": 12
              }
            }
          }
        ]
      }
    }
  }
};

const Dashboard = () => {
  const [showCharts, setShowCharts] = useState(true);
  const [data, setData] = useState<ApiResponse['data'] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [playerId, setPlayerId] = useState('');
  const [isPlayerView, setIsPlayerView] = useState(false);
  const [selectedStage, setSelectedStage] = useState(1);
  const [selectedPlayerAmount, setSelectedPlayerAmount] = useState(1);
  const [selectedDate, setSelectedDate] = useState('');
  const [isDateView, setIsDateView] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const minDate = '2024-11-05';
  const today = new Date().toISOString().split('T')[0];


  const transformTimeData = (data: { playerDeathHistory?: PlayerDeathHistory[] | undefined; deathRanking?: { cause_of_death?: string; cause_id?: number; occurrence_count: number; }[]; taskOrderFrequency?: { task_description: string; task_order?: number; frequency?: number; order_frequencies?: Record<number, number>; }[]; stageDeathRanking?: { stage: number; death_count: number; }[]; playerDeviceCount?: { device_type: string; device_count: number; }[]; sessionPlayerCountRanking?: { session_id: string; players_in_session: number; session_count?: number; }[]; averageStageTime: any; }) => {
    const selectedPlayerData = data.averageStageTime.find((x: { player_amount: number; }) => x.player_amount === selectedPlayerAmount);
    if (!selectedPlayerData) return [];

    return [1, 2, 3, 4].map(stage => ({
      stage: getStageNameFromNumber(stage),
      min: selectedPlayerData.time_taken[stage]?.min || 0,
      avg: selectedPlayerData.time_taken[stage]?.avg || 0,
      max: selectedPlayerData.time_taken[stage]?.max || 0
    }));
  };

  const handleDateSearch = () => {
    if (startDate && endDate) {
      setIsDateView(true);
      fetchData(true);
    }
  };

  const processTaskOrderFrequency = (tasks: TaskOrder[]) => {
    if (tasks.length === 0) return [];

    // Check if we're dealing with player-specific data (has task_order property)
    if ('task_order' in tasks[0]) {
      const groupedTasks = tasks.reduce((acc: Record<string, TaskOrder>, task) => {
        if (!acc[task.task_description]) {
          acc[task.task_description] = {
            task_description: task.task_description,
            order_frequencies: {}
          };
        }
        if (task.task_order && task.frequency) {
          acc[task.task_description].order_frequencies = {
            ...acc[task.task_description].order_frequencies,
            [task.task_order]: task.frequency
          };
        }
        return acc;
      }, {});

      return Object.values(groupedTasks);
    }

    return tasks;
  };

  const processDeathStageData = (deathStageData: Array<{ stage: number; death_count: number }>): DeathStageDataItem[] => {
    const total = deathStageData.reduce((acc, data) => acc + data.death_count, 0);
    return deathStageData.map((data): DeathStageDataItem => {
      const stageName = getStageNameFromNumber(data.stage);
      const percentage = ((data.death_count / total) * 100).toFixed(1);
      return {
        stageName,
        stage: data.stage,
        death_count: data.death_count,
        percentage,
        name: stageName,
        value: data.death_count,
        label: `${data.death_count} (${percentage}%)`,
        color: '#ef4444'
      };
    });
  };
  const processSessionData = (sessionData: ApiResponse['data']['sessionPlayerCountRanking']): ProcessedDataItem[] => {
    const total = sessionData.reduce((acc, session) => acc + (session.session_count || 1), 0);

    return sessionData.map((session): ProcessedDataItem => {
      const value = session.session_count || 1;
      const percentage = ((value / total) * 100).toFixed(1);
      const name = `${session.players_in_session} Player${session.players_in_session > 1 ? 's' : ''}`;
      return {
        name,
        value,
        percentage,
        label: `${name}: ${value} (${percentage}%)`,
        color: session.players_in_session === 1 ? "#3b82f6" :
          session.players_in_session === 2 ? "#10b981" :
            session.players_in_session === 3 ? "#f59e0b" : "#ef4444"
      };
    });
  };

  const processGenderData = (data: any): ProcessedDataItem[] => {
    if (!data.genderDistribution) return [];

    const total = data.genderDistribution.reduce((acc: number, item: Record<string, number>) =>
      acc + Object.values(item)[0], 0);

    return data.genderDistribution.map((item: any): ProcessedDataItem => {
      const value = Object.values(item)[0] as number;
      const percentage = ((value / total) * 100).toFixed(1);
      const name = Object.keys(item)[0];
      return {
        name,
        value,
        percentage,
        label: `${name}: ${value} (${percentage}%)`,
        color: name === 'male' ? '#3b82f6' :
          name === 'female' ? '#ec4899' : '#9ca3af'
      };
    });
  };

  const fetchData = async (playerSpecific: boolean = false) => {
    setLoading(true);

    try {
      // Simulate API delay
      await new Promise(resolve => setTimeout(resolve, 500));

      let result;
      if (playerSpecific) {
        // @ts-ignore - Mock data type
        result = MOCK_DATA.players[playerId];
        if (!result) {
          setError('Player not found');
          setLoading(false);
          return;
        }
      } else {
        result = MOCK_DATA.all;
      }

      if (result.status) {
        // Process the data before setting it
        const transformedData = {
          ...result.data,
          taskOrderFrequency: processTaskOrderFrequency(result.data.taskOrderFrequency)
        };
        setData(transformedData);
        setError(null);
      } else {
        setError(playerSpecific ? 'Data not found' : 'Failed to load data');
      }
    } catch (err) {
      setError('Error fetching data');
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  };

  const handlePlayerSearch = () => {
    if (playerId.trim()) {
      setIsPlayerView(true);
      fetchData(true);
    }
  };

  const handleResetView = () => {
    setSelectedDate('');
    setIsDateView(false);
    fetchData(false);
  };



  useEffect(() => {
    fetchData(false);
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-xl">Loading dashboard data...</p>
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-xl text-red-500">{error || 'Failed to load data'}</p>
      </div>
    );
  }

  const sessionData = data.sessionPlayerCountRanking.map(session => ({
    type: `${session.players_in_session} Player${session.players_in_session > 1 ? 's' : ''}`,
    count: session.session_count,
    color: session.players_in_session === 1 ? "#3b82f6" :
      session.players_in_session === 2 ? "#10b981" :
        session.players_in_session === 3 ? "#f59e0b" : "#ef4444"
  }));

  const stageTimeData = data.averageStageTime;
  const deathStageData = data.stageDeathRanking;
  
  const DeviceDistribution: React.FC<{ data: ApiResponse['data'] }> = ({ data }) => {
    const totalDevices = data.playerDeviceCount.reduce((acc: any, device: { device_count: any; }) => acc + device.device_count, 0);
    
    return (
      <Card>
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-sm font-medium">Device Distribution</CardTitle>
          <Monitor className="w-4 h-4 text-gray-500" />
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {data.playerDeviceCount.map((device: { device_count: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined; device_type: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => {
              const percentage = (((Number(device.device_count) ?? 0) / totalDevices) * 100).toFixed(1);
              return (
                <div key={index} className="flex flex-col">
                  <p className="text-lg font-bold">{device.device_type}</p>
                  <p className="text-sm text-gray-500">
                    Active devices: {device.device_count} ({percentage}%)
                  </p>
                </div>
              );
            })}
          </div>
        </CardContent>
      </Card>
    );
  };
  
  const handleStartDateChange = (e: { target: { value: any; }; }) => {
    const selectedDate = e.target.value;
    if (selectedDate < minDate) {
      alert('Start date cannot be before November 5, 2024');
      return;
    }
    if (selectedDate > today) {
      alert('Start date cannot be in the future');
      return;
    }
    if (endDate && selectedDate > endDate) {
      alert('Start date cannot be after end date');
      return;
    }
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (e: { target: { value: any; }; }) => {
    const selectedDate = e.target.value;
    if (selectedDate > today) {
      alert('End date cannot be in the future');
      return;
    }
    if (startDate && selectedDate < startDate) {
      alert('End date cannot be before start date');
      return;
    }
    setEndDate(selectedDate);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-between mb-8 gap-4">
          <div className="flex items-center">
            <Trophy className="w-8 h-8 mr-3 text-yellow-500" />
            <h1 className="text-3xl font-bold text-gray-800">School of Survival</h1>
          </div>

          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
     <input
        type="text" 
        value={startDate ? startDate : "Start Date"}
        onFocus={(e) => e.target.type = 'date'}
        onBlur={(e) => {
          if (!e.target.value) {
            e.target.type = 'text'
          }
        }}
        onChange={handleStartDateChange}
        min={minDate}
        max={today}
        className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 w-40"
      />
      
      <span>to</span>
      
      <input
        type="text"
        value={endDate ? endDate : "End Date"}
        onFocus={(e) => e.target.type = 'date'}
        onBlur={(e) => {
          if (!e.target.value) {
            e.target.type = 'text'
          }
        }}
        onChange={handleEndDateChange}
        min={startDate || minDate}
        max={today}
        className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 w-40"
      />
              <button
                onClick={handleDateSearch}
                className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                disabled={!startDate || !endDate}
              >
                <Search className="w-4 h-4" />
                <span>Search Date</span>
              </button>
              {isDateView && (
                <button
                  onClick={handleResetView}
                  className="flex items-center gap-2 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
                >
                  <X className="w-4 h-4" />
                  <span>Reset</span>
                </button>
              )}
            </div>

            <button
              onClick={() => setShowCharts(!showCharts)}
              className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow hover:bg-gray-50"
            >
              {showCharts ? (
                <>
                  <Table className="w-4 h-4" />
                  <span>Show Numbers</span>
                </>
              ) : (
                <>
                  <LineChart className="w-4 h-4" />
                  <span>Show Charts</span>
                </>
              )}
            </button>
          </div>
        </div>

        {isDateView && (
          <div className="mb-4 p-4 bg-blue-50 rounded-lg">
            <p className="text-blue-800">
              Viewing data from{' '}
              <span className="font-bold">
                {new Date(startDate).toLocaleDateString('en-US', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </span>
              {' '}to{' '}
              <span className="font-bold">
                {new Date(endDate).toLocaleDateString('en-US', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </span>
            </p>
          </div>
        )}

  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <Card className="col-span-2">
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium">
                {isPlayerView ? "Player Fail History" : "Fail Ranking"}
              </CardTitle>
              <Skull className="w-4 h-4 text-gray-500" />
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                {(() => {
                  // Merge fails with same cause_id across all stages
                  const mergedDeaths = data.deathRanking.reduce((acc, death) => {
                    const existingDeath = acc.find(d => d.cause_id === death.cause_id);
                    if (existingDeath) {
                      existingDeath.occurrence_count += death.occurrence_count;
                    } else {
                      acc.push({ ...death });
                    }
                    return acc;
                  }, [] as typeof data.deathRanking);

                  // Sort by occurrence count
                  return mergedDeaths
                    .sort((a, b) => b.occurrence_count - a.occurrence_count)
                    .map((death, index) => (
                      <div key={index} className="flex justify-between items-center p-2 bg-gray-50 rounded">
                        <div className="flex flex-col">
                          <span className="text-sm font-medium">
                            {death.cause_id !== undefined ?
                              DEATH_CAUSES[death.cause_id] :
                              'Unknown cause'}
                          </span>
                        </div>
                        <span className="text-sm font-bold">
                          {death.occurrence_count}
                        </span>
                      </div>
                    ));
                })()}
              </div>
            </CardContent>
          </Card>

          <DeviceDistribution data={data} />

 

          <Card>
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium">
                Most Fail Stage
              </CardTitle>
              <Skull className="w-4 h-4 text-gray-500" />
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                {(() => {
                  // Calculate total deaths per stage
                  const stageOccurrences: Record<string, number> = data.deathRanking.reduce((acc, death) => {
                    const stage = death.stage.toString();
                    acc[stage] = (acc[stage] || 0) + death.occurrence_count;
                    return acc;
                  }, {} as Record<string, number>);

                  const mostCommonStage = Object.entries(stageOccurrences)
                    .reduce((a, b) => (b[1] > a[1] ? b : a));

                  const totalDeaths = mostCommonStage[1];
                  const stageNumber = mostCommonStage[0];

                  // Get deaths for this stage to calculate percentages
                  const stageDeaths = data.deathRanking
                    .filter(death => death.stage === Number(stageNumber));

                  return (
                    <>
                      <p className="text-lg font-bold">
                        Stage {stageNumber === '1' ? '1-1' : stageNumber}
                      </p>
                      <p className="text-sm text-gray-500">
                        Total deaths: {totalDeaths}
                      </p>
                      <div className="mt-4">
                        <p className="text-sm font-medium mb-2">Top Fail Causes:</p>
                        {stageDeaths
                          .sort((a, b) => b.occurrence_count - a.occurrence_count)
                          .slice(0, 3)
                          .map((death, index) => (
                            <div key={index} className="text-sm text-gray-600 flex justify-between items-center">
                              <span>{DEATH_CAUSES[death.cause_id]}</span>
                              <div className="text-right">
                                <span className="font-medium">{death.occurrence_count}</span>
                                <span className="text-gray-400 ml-2">
                                  ({((death.occurrence_count / totalDeaths) * 100).toFixed(1)}%)
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  );
                })()}
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <Card>
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium">
                Skin Gender Distribution
              </CardTitle>
              <Users className="w-4 h-4 text-gray-500" />
            </CardHeader>
            <CardContent>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={processGenderData(data)}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      label={({ value, percentage }) => `${value} (${percentage}%)`}
                    >
                      {processGenderData(data).map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Session Types Distribution</CardTitle>
            </CardHeader>
            <CardContent>
              {showCharts ? (
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={processSessionData(data.sessionPlayerCountRanking)}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        label={({ value, percentage }) => `${value} (${percentage}%)`}
                      >
                        {processSessionData(data.sessionPlayerCountRanking).map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <Tooltip content={<CustomTooltip />} />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <div className="space-y-4">
                  {processSessionData(data.sessionPlayerCountRanking).map((item, index) => (
                    <div key={index} className="flex justify-between items-center">
                      <span className="text-sm font-medium">{item.name}</span>
                      <span className="text-lg font-bold">
                        {item.value} ({item.percentage}%)
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </CardContent>
          </Card>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center justify-between">
                <div className="flex items-center">
                  <Clock className="w-4 h-4 mr-2" />
                  Stage Completion Times (seconds)
                </div>
                <select
                  value={selectedPlayerAmount}
                  onChange={(e) => setSelectedPlayerAmount(Number(e.target.value))}
                  className="px-2 py-1 border rounded"
                >
                  {[1, 2, 3, 4].map(num => (
                    <option key={num} value={num}>{num} Player{num > 1 ? 's' : ''}</option>
                  ))}
                </select>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={transformTimeData(data)} barGap={0}>
                    <XAxis dataKey="stage" />
                    <YAxis label={{ value: 'Seconds', angle: -90, position: 'insideLeft' }} />
                    <Tooltip formatter={(value) => `${value}s`} />
                    <Legend />
                    <Bar dataKey="min" name="Minimum" fill="#10b981">
                      <LabelList dataKey="min" position="top" formatter={(value: any) => `${value}s`} />
                    </Bar>
                    <Bar dataKey="avg" name="Average" fill="#3b82f6">
                      <LabelList dataKey="avg" position="top" formatter={(value: any) => `${value}s`} />
                    </Bar>
                    <Bar dataKey="max" name="Maximum" fill="#ef4444">
                      <LabelList dataKey="max" position="top" formatter={(value: any) => `${value}s`} />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>


          <Card>
            <CardHeader>
              <CardTitle>Fail per Stage</CardTitle>
            </CardHeader>
            <CardContent>
              {showCharts ? (
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={processDeathStageData(deathStageData)}>
                      <XAxis dataKey="stageName" />
                      <YAxis />
                      <Tooltip content={<CustomTooltip />} />
                      <Bar dataKey="death_count" fill="#ef4444" name="Deaths">
                        <LabelList
                          dataKey="label"
                          position="top"
                          fill="#000000"
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <div className="space-y-4">
                  {processDeathStageData(deathStageData).map((item, index) => (
                    <div key={index} className="flex justify-between items-center">
                      <span className="text-sm font-medium">{item.stageName}</span>
                      <span className="text-lg font-bold">{item.label}</span>
                    </div>
                  ))}
                </div>
              )}
            </CardContent>
          </Card>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

          <Card>
            <CardHeader>
              <CardTitle className="flex items-center justify-between">
                <span>Task Order Frequency</span>
                <select
                  value={selectedStage}
                  onChange={(e) => setSelectedStage(Number(e.target.value))}
                  className="ml-4 px-2 py-1 border rounded"
                >
                  <option value={1}>{getStageNameFromNumber(1)}</option>
                  <option value={2}>{getStageNameFromNumber(2)}</option>
                </select>

              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {processTaskOrderFrequency(data.taskOrderFrequency)
                  .filter(task => task.stage === selectedStage)
                  .map((task, index) => (
                    <div key={index} className="space-y-2">
                      <div className="font-medium">{task.task_description}</div>
                      <div className="grid grid-cols-6 gap-2">
                        {[1, 2, 3, 4, 5, 6].map(order => (
                          <div
                            key={order}
                            className="text-center p-2 rounded"
                            style={{
                              backgroundColor: task.order_frequencies?.[order]
                                ? `rgba(16, 185, 129, ${task.order_frequencies[order] / 300})`
                                : 'rgba(229, 231, 235, 0.5)'
                            }}
                          >
                            <div className="text-xs">Order {order}</div>
                            <div className="font-bold">
                              {task.order_frequencies?.[order] || 0}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;