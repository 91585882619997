// src/components/NavCircle.tsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface NavItemProps {
  isActive: boolean;
  onClick: () => void;
  iconSrc: string;
}

const NavItem: React.FC<NavItemProps> = ({ isActive, onClick, iconSrc }) => (
  <button
    onClick={onClick}
    className={`w-12 h-12 rounded-full flex items-center justify-center focus:outline-none ${
      isActive ? 'bg-blue-500' : 'bg-gray-300'
    }`}
  >
    <img src={iconSrc} alt="nav-icon" className="w-8 h-8" />
  </button>
);

const NavCircle: React.FC = () => {
  const [activeNavItem, setActiveNavItem] = React.useState(0);
  const location = useLocation();

  // Hide the navigation on the Game page
  if (location.pathname.includes('/game')) {
    return null;
  }

  return (
    <nav className="fixed bottom-4 w-full flex justify-center items-center">
      <div className="flex space-x-4">
        <Link to="/">
          <NavItem
            isActive={location.pathname === '/'}
            onClick={() => setActiveNavItem(0)}
            iconSrc="/path/to/home-icon.jpg"
          />
        </Link>
        <Link to="/WordGuesser">
          <NavItem
            isActive={location.pathname === '/WordGuesser'}
            onClick={() => setActiveNavItem(1)}
            iconSrc="/path/to/WordGuesser-icon.jpg"
          />
        </Link>
        {/* Add more navigation items as needed */}
      </div>
    </nav>
  );
};

export default NavCircle;
