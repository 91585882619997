import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import Badge from '../components/ui/badge';
import { Timer, Trophy, Users, Crown, Target, UserCheck } from 'lucide-react';
import Input from '../components/ui/input';
import classNames from 'classnames';
import io from 'socket.io-client';
import { Socket } from 'socket.io-client';

const WordGuessingGame = () => {
  // Socket and player state

  const [socket, setSocket] = useState<Socket | null>(null);
  const [playerName, setPlayerName] = useState('');
  const [isJoined, setIsJoined] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [connectionAttempts, setConnectionAttempts] = useState(0);
  const [connectionStatus, setConnectionStatus] = useState('Initializing connection...');
  const [sessionName, setSessionName] = useState('');
  const [showSessionNameInput, setShowSessionNameInput] = useState(false);
  const [currentSessionName, setCurrentSessionName] = useState('');


  const MAX_ATTEMPTS = 5;

  // Game state that will be synchronized with server
  const [gameState, setGameState] = useState({
    players: {} as Record<string, { name: string, score: number, isReady: boolean }>,
    currentRound: 1,
    gamePhase: 'lobby',
    timeLeft: 30,
    selectedWords: {} as Record<string, { word: string, score: number }>,
    countdown: null,
    currentHint: null as { question: string } | null,
    availableWords: [] as { ID: string, word: string, score: number }[],

    experimentProgress: {
      currentGame: 1,
      totalGames: 10,
      scores: [] as Array<{
        playerId: string;
        playerName: string;
        score: number;
        gameNumber: number;
      }>
    },
    betweenGamesCountdown: null as number | null
  });

  const [availableSessions, setAvailableSessions] = useState<Array<{
    id: string;
    name: string;
    playerCount: number;
    gamePhase: string;
  }>>([]);
  const [selectedSession, setSelectedSession] = useState<string>('');

  // Track revealed scores locally
  const [revealedScores, setRevealedScores] = useState<Record<string, boolean>>({});
  const [roundBestWord, setRoundBestWord] = useState<{ player: string, word: { word: string, score: number } } | null>(null);

  const BetweenGamesState = () => {
    // Get scores from the previous game
    const calculateTotalScore = (playerId: string) => {
      return gameState.experimentProgress.scores
          .filter(score => score.playerId === playerId)
          .reduce((total, score) => total + score.score, 0);
  };

    const previousGameScores = gameState.experimentProgress.scores
      .filter(score => score.gameNumber === gameState.experimentProgress.currentGame - 1);

    return (
      <Card className="mb-4">
        <CardHeader>
          <CardTitle className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <span>Game {gameState.experimentProgress.currentGame} of {gameState.experimentProgress.totalGames}</span>
            </div>
            <div className="flex items-center gap-2">
              <Timer className="w-5 h-5" />
              <span>Next game in {gameState.betweenGamesCountdown}s</span>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-center space-y-4">
            <h3 className="text-xl font-bold">Preparing Next Game</h3>

            {/* Progress Bar */}
            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
              <div
                className="bg-blue-600 h-2.5 rounded-full transition-all"
                style={{ width: `${(gameState.experimentProgress.currentGame / 10) * 100}%` }}
              />
            </div>

            {/* Game Progress Indicators */}
            <div className="grid grid-cols-10 gap-1">
              {Array.from({ length: 10 }, (_, i) => (
                <div
                  key={i}
                  className={`h-2 rounded ${i < gameState.experimentProgress.currentGame - 1
                      ? 'bg-green-500'
                      : i === gameState.experimentProgress.currentGame - 1
                        ? 'bg-blue-500'
                        : 'bg-gray-200'
                    }`}
                />
              ))}
            </div>

            {/* Previous Game Score */}
            <div className="p-4 bg-blue-50 rounded-lg">
              <p className="font-medium mb-2">Previous Game Scores:</p>
              <div className="space-y-2">
                {previousGameScores.length > 0 ? (
                  previousGameScores.map((score) => (
                    <div key={score.playerId} className="flex justify-between">
                      <span>{score.playerName}</span>
                      <span className="font-semibold">{score.score.toFixed(0)} points</span>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">First game - no previous scores</p>
                )}
              </div>
            </div>

            {/* Current Progress */}
            <div className="p-4 bg-green-50 rounded-lg">
                <p className="font-medium mb-2">Overall Progress:</p>
                <div className="space-y-2">
                    {Object.entries(gameState.players).map(([playerId, player]) => {
                        const totalScore = calculateTotalScore(playerId);
                        
                        return (
                            <div key={playerId} className="flex justify-between">
                                <span>{player.name}</span>
                                <span className="font-semibold">{totalScore.toFixed(0)} total points</span>
                            </div>
                        );
                    })}
                </div>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  const ExperimentCompleteState = () => {
    const calculateTotalScore = (playerId: string) => {
      return gameState.experimentProgress.scores
        .filter(score => score.playerId === playerId)
        .reduce((total, score) => total + score.score, 0);
    };
  
    const calculateAverageScore = (playerId: string) => {
      const playerScores = gameState.experimentProgress.scores
        .filter(score => score.playerId === playerId);
      return playerScores.length > 0 
        ? playerScores.reduce((total, score) => total + score.score, 0) / playerScores.length 
        : 0;
    };
  
    // Create an array of unique game numbers from the scores
    const uniqueGameNumbers = Array.from(
      new Set(gameState.experimentProgress.scores.map(score => score.gameNumber))
    ).sort((a, b) => a - b);
  
    return (
      <Card className="mb-4">
        <CardHeader>
          <CardTitle className="text-center">Experiment Complete!</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <div className="text-center">
              <Trophy className="w-12 h-12 mx-auto text-yellow-500 mb-4" />
              <h3 className="text-2xl font-bold mb-4">Final Results</h3>
            </div>
  
            {/* Player Statistics */}
            <div className="space-y-4">
              {Object.entries(gameState.players).map(([playerId, player]) => (
                <Card key={playerId} className="p-4">
                  <div className="space-y-2">
                    <h4 className="font-semibold text-lg">{player.name}</h4>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="p-3 bg-blue-50 rounded">
                        <p className="text-sm text-gray-600">Total Score</p>
                        <p className="text-lg font-bold">{calculateTotalScore(playerId)}</p>
                      </div>
                      <div className="p-3 bg-green-50 rounded">
                        <p className="text-sm text-gray-600">Average Score</p>
                        <p className="text-lg font-bold">{calculateAverageScore(playerId).toFixed(1)}</p>
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
  
            {/* Game History */}
            <div className="mt-6">
              <h4 className="font-semibold mb-3">Game History</h4>
              <div className="space-y-2">
                {uniqueGameNumbers.map(gameNum => {
                  // Get only the first score entry for each player in this game
                  const gameScores = gameState.experimentProgress.scores
                    .filter(score => score.gameNumber === gameNum)
                    .reduce((unique: typeof gameState.experimentProgress.scores, score) => {
                      const existingScore = unique.find(s => s.playerId === score.playerId);
                      if (!existingScore) {
                        unique.push(score);
                      }
                      return unique;
                    }, []);
  
                  return (
                    <div key={gameNum} className="p-3 bg-gray-50 rounded">
                      <p className="font-medium">Game {gameNum}</p>
                      <div className="mt-1 space-y-1">
                        {gameScores.map(score => (
                          <div key={score.playerId} className="flex justify-between text-sm">
                            <span>{score.playerName}</span>
                            <span>{score.score}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
  
            <Button
              onClick={() => window.location.reload()}
              className="w-full mt-6"
            >
              Start New Experiment
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  };

  useEffect(() => {
    if (gameState.gamePhase === 'betweenGames' ||
      (gameState.gamePhase === 'playing' && gameState.currentRound === 1)) {
      setRevealedScores({}); // Reset revealed scores
      setRoundBestWord(null); // Reset best word
    }
  }, [gameState.gamePhase, gameState.currentRound]);


  // Socket connection setup
  useEffect(() => {
    setConnectionStatus('Attempting to connect to server...');
    let attemptCount = 0;
    let connectionTimer: NodeJS.Timeout | null = null;

    const attemptConnection = () => {
      //  http://localhost:3001/
      //  https://wordguesserver.kantinanplayground.com/
      //  https://expguesserver.kantinanplayground.com/

      const newSocket = io('https://expguesserver.kantinanplayground.com/', {
        transports: ['websocket'],
        reconnection: true,
        reconnectionAttempts: MAX_ATTEMPTS,
        reconnectionDelay: 1000,
        timeout: 5000
      });


      newSocket.on('connect', () => {
        console.log('Connected to server with ID:', newSocket.id);
        setConnectionStatus('Connected!');
        setSocket(newSocket);
      });

      newSocket.on('disconnect', () => {
        console.log('Disconnected from server');
      });

      newSocket.on('connect_error', (error) => {
        attemptCount++;
        setConnectionAttempts(attemptCount);
        console.error('Connection error:', error);
        setConnectionStatus(`Connection attempt ${attemptCount}/${MAX_ATTEMPTS} failed. Retrying...`);

        if (attemptCount >= MAX_ATTEMPTS) {
          setConnectionStatus('Unable to connect to server. Please refresh the page to try again.');
        }
      });

      newSocket.on('gameStateUpdate', (newGameState) => {
        console.log('Game state updated:', newGameState);
        setGameState(newGameState);

        // Check if there's a best word when round ends
        if (newGameState.gamePhase === 'roundEnd') {
          const bestSelection = findRoundBestWord(newGameState.selectedWords);
          setRoundBestWord(bestSelection);
        }

        // Reset countdown if all players unready
        if (newGameState.gamePhase === 'lobby') {
          const readyPlayers = Object.values(newGameState.players).filter((p) => (p as { name: string, score: number, isReady: boolean }).isReady).length;
          if (readyPlayers === 0) {
            // Emit event to server to cancel countdown
            socket?.emit('cancelCountdown');
          }
        }
      });
      return newSocket;
    };

    const newSocket = attemptConnection();



    // Cleanup function
    return () => {
      if (socket) {
        console.log('Cleaning up socket connection');
        socket.disconnect();
        setSocket(null);
        setIsJoined(false);
      }
      if (connectionTimer) {
        clearTimeout(connectionTimer);
      }
    };
  }, []);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        //const response = await fetch('http://localhost:3001/api/sessions');
        const response = await fetch('https://expguesserver.kantinanplayground.com/api/experiments');
        const sessions = await response.json();
        setAvailableSessions(sessions);
      } catch (error) {
        console.error('Error fetching experiment  sessions:', error);
      }
    };

    fetchSessions();
    // Refresh sessions list every 5 seconds
    const interval = setInterval(fetchSessions, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (roundBestWord && gameState.gamePhase === 'roundEnd') {
      const bestWordId = gameState.availableWords.find(w => w.word === roundBestWord.word.word)?.ID;
      if (bestWordId) {
        setRevealedScores(prev => ({
          ...prev,
          [bestWordId]: true
        }));
      }
    }
  }, [roundBestWord, gameState.gamePhase]);

  useEffect(() => {
    if (socket) {
      socket.on('experimentTimerUpdate', (timeLeft: number) => {
        setGameState(prev => ({
          ...prev,
          timeLeft: timeLeft
        }));
      });

      return () => {
        socket.off('experimentTimerUpdate');
      };
    }
  }, [socket]);


  // Modified handleJoinGame with better error handling
  const handleJoinGame = (existingSessionId?: string) => {
    if (!socket) {
      console.error('No socket connection available');
      setConnectionStatus('Connection failed. Please refresh the page.');
      return;
    }

    if (!playerName.trim()) {
      return;
    }

    // Add check for full session
    if (existingSessionId !== 'new') {
      const session = availableSessions.find(s => s.id === existingSessionId);
      if (session && session.playerCount >= 5) {
        setConnectionStatus('This session is full. Please choose another session.');
        return;
      }
    }

    if (selectedSession === 'new' && !sessionName.trim()) {
      alert('Please enter a session name');
      return;
    }

    setConnectionStatus('Joining experiment...');

    socket.emit('joinExperiment', {
      sessionId: existingSessionId || 'new',
      playerName: playerName.trim(),
      sessionName: sessionName.trim()
    });

    socket.once('joinExperimentResponse', (response) => {
      if (response.success) {
        setIsJoined(true);
        setConnectionStatus('Joined successfully!');
        setCurrentSessionName(response.sessionName);
        localStorage.setItem('currentExperimentSession', response.sessionId);
      } else {
        setConnectionStatus(`Failed to join: ${response.error || 'Unknown error'}`);
        setTimeout(() => window.location.reload(), 3000);
      }
    });

    setTimeout(() => {
      if (!isJoined) {
        setConnectionStatus('Join request timed out. Please refresh the page.');
      }
    }, 5000);
  };

  // Also update your socket event listeners in the useEffect:
  useEffect(() => {
    setConnectionStatus('Attempting to connect to server...');
    let attemptCount = 0;
    let connectionTimer: NodeJS.Timeout | null = null;

    const attemptConnection = () => {
      const newSocket = io('https://expguesserver.kantinanplayground.com/', {
        transports: ['websocket'],
        reconnection: true,
        reconnectionAttempts: MAX_ATTEMPTS,
        reconnectionDelay: 1000,
        timeout: 5000
      });

      newSocket.on('connect', () => {
        console.log('Connected to server with ID:', newSocket.id);
        setConnectionStatus('Connected!');
        setSocket(newSocket);
      });

      newSocket.on('disconnect', () => {
        console.log('Disconnected from server');
      });

      newSocket.on('connect_error', (error) => {
        attemptCount++;
        setConnectionAttempts(attemptCount);
        console.error('Connection error:', error);
        setConnectionStatus(`Connection attempt ${attemptCount}/${MAX_ATTEMPTS} failed. Retrying...`);

        if (attemptCount >= MAX_ATTEMPTS) {
          setConnectionStatus('Unable to connect to server. Please refresh the page to try again.');
        }
      });

      // Change to experimentStateUpdate
      newSocket.on('experimentStateUpdate', (newGameState) => {
        console.log('Experiment state updated:', newGameState);
        setGameState(newGameState);

        if (newGameState.gamePhase === 'roundEnd') {
          const bestSelection = findRoundBestWord(newGameState.selectedWords);
          setRoundBestWord(bestSelection);
        }

        if (newGameState.gamePhase === 'lobby') {
          const readyPlayers = Object.values(newGameState.players)
            .filter((p) => (p as { name: string, score: number, isReady: boolean }).isReady).length;
          if (readyPlayers === 0) {
            newSocket.emit('cancelExperimentCountdown');
          }
        }
      });

      // Add experimentTimerUpdate listener here
      newSocket.on('experimentTimerUpdate', (timeLeft: number) => {
        setGameState(prev => ({
          ...prev,
          timeLeft: timeLeft
        }));
      });

      return newSocket;
    };

    const newSocket = attemptConnection();

    return () => {
      if (socket) {
        console.log('Cleaning up socket connection');
        socket.disconnect();
        setSocket(null);
        setIsJoined(false);
      }
      if (connectionTimer) {
        clearTimeout(connectionTimer);
      }
    };
  }, []);

  // Update the localStorage sync useEffect:
  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'currentExperimentSession' && e.newValue && !isJoined && socket) {
        handleJoinGame(e.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    const existingSession = localStorage.getItem('currentExperimentSession');
    if (existingSession && !isJoined && socket) {
      handleJoinGame(existingSession);
    }

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [socket, isJoined]);

  const toggleReady = () => {
    if (socket && gameState.gamePhase === 'lobby') {
      socket.emit('toggleExperimentReady');
    }
  };


  const selectWord = (word: { ID: string, word: string, score: number }) => {
    if (socket && socket.id && gameState.gamePhase === 'playing' &&
      !gameState.selectedWords[socket.id]) {
      socket.emit('selectExperimentWord', word);

      setRevealedScores(prev => ({
        ...prev,
        [word.ID]: true
      }));
    }
  };

  const findRoundBestWord = (selections: { [key: string]: { word: string, score: number } }) => {
    if (!selections || Object.keys(selections).length === 0) return null;

    let bestWord = null;
    let highestScore = -1;
    let bestPlayerId = '';

    Object.entries(selections).forEach(([playerId, selection]) => {
      if (selection.score > highestScore) {
        highestScore = selection.score;
        bestWord = selection;
        bestPlayerId = playerId;
      }
    });

    return bestWord ? {
      player: gameState.players[bestPlayerId]?.name || 'Unknown',
      word: bestWord,
      playerId: bestPlayerId  // Add this to track who selected it
    } : null;
  };

  const getScoreColor = (score: number) => {
    const red = Math.round(255 * (1 - score));
    const green = Math.round(255 * score);
    return `rgb(${red}, ${green}, 0)`;
  };

  const determineWinner = () => {
    const players = gameState.players;
    if (!players || Object.keys(players).length === 0) return null;

    const highestScore = Math.max(...Object.values(players).map(p => p.score));
    const winners = Object.values(players).filter(p => p.score === highestScore);

    if (winners.length === 1) {
      return winners[0].name;
    } else {
      return 'Tie!';
    }
  };

  const renderWordButton = (word: { ID: string, word: string, score: number }) => {
    const mySelection = socket?.id ? gameState.selectedWords[socket.id] : undefined;
    const isSelected = mySelection?.word === word.word;
    const isRoundBest = roundBestWord?.word.word === word.word;
    const isScoreRevealed =
      (gameState.gamePhase === 'playing' && revealedScores[word.ID]) || // Only show during playing if specifically revealed
      (gameState.gamePhase === 'roundEnd' && (revealedScores[word.ID] || isRoundBest)) || // Show during round end
      gameState.gamePhase === 'gameOver'; // Show all at game over


    let buttonStyle: React.CSSProperties = {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      transition: 'all 0.3s ease',
      border: '2px solid #e2e8f0',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    };

    if (isScoreRevealed) {
      buttonStyle = {
        ...buttonStyle,
        //backgroundColor: getScoreColor(word.score),
        //color: word.score > 0.5 ? 'white' : 'black',
        // border: 'none',
        // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      };
    }

    const buttonClassName = classNames(
      "relative p-0",
      !isScoreRevealed && "hover:border-blue-400 hover:shadow-md",
      isSelected && "ring-2 ring-blue-500"
    );

    return (
      <div key={word.ID} className="w-full h-16">
        <Button
          onClick={() => selectWord(word)}
          disabled={socket?.id ? gameState.selectedWords[socket.id] !== undefined : true}
          style={buttonStyle}
          variant="custom"
          className={buttonClassName}
        >
          {isRoundBest && gameState.gamePhase !== 'playing' && (
            <Crown className="w-4 h-4 absolute -top-2 -right-2 text-yellow-500" />
          )}
          <span className="text-sm font-medium">{word.word}</span>
          {isScoreRevealed && (
            <span className="text-xs font-medium">
              {(word.score * 100).toFixed(0)}%
            </span>
          )}
        </Button>
      </div>
    );
  };
  // Join game UI
  if (!isJoined) {
    return (
      <Card className="w-full max-w-md mx-auto mt-8 p-6">
        <CardHeader>
          <CardTitle>Join Word Guessing Game with Hint</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {!socket ? (
              <div className="space-y-4">
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div
                    className="bg-blue-600 h-2.5 rounded-full transition-all duration-500"
                    style={{
                      width: `${(connectionAttempts / MAX_ATTEMPTS) * 100}%`,
                    }}
                  />
                </div>
                <div className="text-center">
                  <p className="text-sm text-gray-600">{connectionStatus}</p>
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                {/* Player Name Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Your Name
                  </label>
                  <Input
                    type="text"
                    value={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                    placeholder="Enter your name"
                  />
                </div>

                {/* Session Selection */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Select Session
                  </label>
                  <div className="space-y-2">
                    {/* Create New Session Button */}
                    <Button
                      onClick={() => {
                        setSelectedSession('new');
                        setShowSessionNameInput(true);
                      }}
                      className="w-full"
                      variant={selectedSession === 'new' ? "default" : "outline"}
                    >
                      Create New Session
                    </Button>

                    {/* Session Name Input */}
                    {showSessionNameInput && selectedSession === 'new' && (
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Session Name
                        </label>
                        <Input
                          type="text"
                          value={sessionName}
                          onChange={(e) => setSessionName(e.target.value)}
                          placeholder="Enter session name"
                        />
                      </div>
                    )}

                    {/* Available Sessions List */}
                    {availableSessions.length > 0 && (
                      <div className="mt-4 space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Available Sessions
                        </label>
                        <div className="space-y-2 max-h-60 overflow-y-auto">
                          {availableSessions.map(session => {
                            const isFull = session.playerCount >= 5;
                            return (
                              <Button
                                key={session.id}
                                onClick={() => {
                                  setSelectedSession(session.id);
                                  setShowSessionNameInput(false);
                                }}
                                className="w-full flex justify-between items-center px-4 py-2"
                                variant={selectedSession === session.id ? "default" : "outline"}
                                disabled={isFull}
                              >
                                <div className="flex flex-col items-start text-left">
                                  <span className="font-medium">{session.name}</span>
                                  <span className="text-sm opacity-70">
                                    {session.playerCount} {session.playerCount === 1 ? 'player' : 'players'} - {session.gamePhase}
                                    {isFull && ' (Full)'}
                                  </span>
                                </div>
                              </Button>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Join/Create Button */}
                <Button
                  onClick={() => handleJoinGame(selectedSession === 'new' ? undefined : selectedSession)}
                  className="w-full mt-4"
                  disabled={!playerName.trim() ||
                    (selectedSession === 'new' && !sessionName.trim()) ||
                    !selectedSession}
                >
                  {selectedSession === 'new' ? 'Create & Join Game' : 'Join Game'}
                </Button>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    );
  }

  // Main game UI
  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <Card className="mb-4">
        <CardHeader>
          <CardTitle className="flex justify-between items-center">
            {gameState.gamePhase === 'lobby' ? (
              <div className="flex items-center gap-2">
                <span>Game Lobby - {currentSessionName}</span>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <span>
                  {currentSessionName} - Game {gameState.experimentProgress.currentGame}/10
                  {gameState.gamePhase === 'playing' && ` - Round ${gameState.currentRound}/4`}
                </span>
              </div>
            )}
            {gameState.gamePhase === 'lobby' && gameState.countdown !== null && (
              <div className="flex items-center gap-2">
                <Timer className="w-5 h-5" />
                <span>Starting in {gameState.countdown}s</span>
              </div>
            )}
            {gameState.gamePhase === 'playing' && (
              <div className="flex items-center gap-2">
                <Timer className="w-5 h-5" />
                <span>{gameState.timeLeft}s</span>
              </div>
            )}
          </CardTitle>
        </CardHeader>
        <CardContent>
          {gameState.gamePhase === 'lobby' && (
            <div className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(gameState.players).map(([playerId, player]) => (
                  <Card key={playerId} className="p-4">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <UserCheck className={`w-5 h-5 ${player.isReady ? 'text-green-500' : 'text-gray-400'}`} />
                        <span>{player.name}</span>
                      </div>
                      {socket && playerId === socket.id && (
                        <Button
                          onClick={toggleReady}
                          className={player.isReady ? "outline" : "default"}
                        >
                          {player.isReady ? "Unready" : "Ready"}
                        </Button>
                      )}
                    </div>
                  </Card>
                ))}
              </div>
              <div className="text-center text-sm text-gray-500">
                {Object.keys(gameState.players).length} players connected,{' '}
                {Object.values(gameState.players).filter(p => p.isReady).length} ready
              </div>
            </div>
          )}

          {gameState.gamePhase === 'playing' && (
            <>
              <div className="mb-6 p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold mb-2">Hint:</h3>
                <p>{gameState.currentHint?.question}</p>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
                {gameState.availableWords.map((word) => renderWordButton(word))}
              </div>
            </>
          )}

          {gameState.gamePhase === 'betweenGames' && <BetweenGamesState />}
          {gameState.gamePhase === 'experimentComplete' && <ExperimentCompleteState />}

          {gameState.gamePhase === 'roundEnd' && (
            <div className="text-center">
              <h3 className="text-xl font-bold mb-4">Round {gameState.currentRound} Results</h3>
              <div className="space-y-4">
                {socket?.id && gameState.selectedWords[socket.id] && (
                  <div className="p-4 bg-gray-100 rounded-lg">
                    <p className="font-medium">Your selected word: {gameState.selectedWords[socket.id].word}</p>
                    <p className="text-lg mt-2">Score: {(gameState.selectedWords[socket.id].score * 100).toFixed(0)}%</p>
                  </div>
                )}
                {roundBestWord && (
                  <div className="p-4 bg-yellow-50 rounded-lg border-2 border-yellow-200">
                    <div className="flex items-center justify-center gap-2 mb-2">
                      <Crown className="w-5 h-5 text-yellow-500" />
                      <h4 className="font-semibold">Best Word This Round</h4>
                    </div>
                    <p className="text-lg">{roundBestWord.word.word}</p>
                    <p className="text-sm mt-1">Score: {(roundBestWord.word.score * 100).toFixed(0)}%</p>
                    {/* Add this line to show who selected it */}
                    <p className="text-sm text-gray-500 mt-1">Selected by: {
                      Object.entries(gameState.players)
                        .find(([id, _]) => Object.entries(gameState.selectedWords)
                          .find(([playerId, selection]) =>
                            selection.word === roundBestWord.word.word &&
                            selection.score === roundBestWord.word.score
                          )?.[0] === id
                        )?.[1].name || 'Unknown'
                    }</p>
                  </div>
                )}
              </div>
            </div>
          )}

          {gameState.gamePhase === 'gameOver' && (
            <div className="text-center">
              <h3 className="text-xl font-bold mb-4">Game Over!</h3>

              <div className="mb-6 p-4 bg-yellow-50 rounded-lg border-2 border-yellow-200">
                <div className="flex items-center justify-center gap-2 mb-2">
                  <Trophy className="w-8 h-8 text-yellow-500" />
                  <h4 className="text-lg font-semibold">
                    {determineWinner() === 'Tie!' ? "It's a Tie!" : `Winner: ${determineWinner()}!`}
                  </h4>
                </div>
                <p className="text-sm mt-2">
                  Highest Score: {Math.max(...Object.values(gameState.players).map(p => p.score)).toFixed(0)} points
                </p>
              </div>

              <div className="mb-4 p-3 bg-blue-50 rounded-lg border border-blue-200">
                <Target className="w-5 h-5 text-blue-500 mx-auto mb-2" />
                {Object.values(gameState.selectedWords).some(word => word.score === 1) ? (
                  <p className="text-sm">
                    Target word "{gameState.availableWords.find(w => w.score === 1)?.word}" was found!
                  </p>
                ) : (
                  <div>
                    <p className="text-sm text-gray-600 mb-1">No one found the target word:</p>
                    <p className="font-semibold text-blue-700">
                      "{gameState.availableWords.find((w: { ID: string, word: string, score: number }) => w.score === 1)?.word}"
                    </p>
                  </div>
                )}
              </div>

              <div className="space-y-2 mt-4">
                {Object.entries(gameState.players)
                  .sort(([_, playerA], [__, playerB]) => playerB.score - playerA.score)
                  .map(([playerId, player], index) => {
                    const isWinner = determineWinner() === player.name ||
                      (determineWinner() === 'Tie!' &&
                        player.score === Math.max(...Object.values(gameState.players).map(p => p.score)));

                    return (
                      <div key={playerId}
                        className={`flex justify-between items-center p-2 rounded
                          ${isWinner ? 'bg-yellow-50 border-2 border-yellow-200' : 'bg-gray-100'}`}
                      >
                        <span className="flex items-center gap-2">
                          {isWinner && <Trophy className="w-4 h-4 text-yellow-500" />}
                          <span className="font-semibold">#{index + 1}</span>
                          {player.name}
                        </span>
                        <Badge variant="default">
                          {player.score.toFixed(0)} points
                        </Badge>
                      </div>
                    );
                  })}
              </div>

              <Button
                onClick={() => window.location.reload()}
                className="mt-6"
              >
                Back to Lobby
              </Button>
            </div>
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Users className="w-5 h-5" />
            <span>Players</span>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            {Object.entries(gameState.players).map(([playerId, player]) => (
              <div key={playerId} className="flex justify-between items-center">
                <span className="flex items-center gap-2">
                  {player.name}
                  {player.isReady && gameState.gamePhase === 'lobby' && (
                    <UserCheck className="w-4 h-4 text-green-500" />
                  )}
                </span>
                <Badge>{player.score?.toFixed(0) || 0} points</Badge>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default WordGuessingGame;