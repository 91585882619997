// src/components/DraggableItem.tsx
import React from 'react';

interface Item {
  id: string;
  content: string;
  info: string;
  position?: string;
}

const DraggableItem = ({ item, onDragStart }: { item: Item; onDragStart: (e: React.DragEvent<HTMLDivElement>, item: Item) => void }) => {
  return (
    <div
      draggable
      onDragStart={(e) => onDragStart(e, item)}
      className="bg-white p-4 mb-4 rounded-lg shadow cursor-move hover:shadow-lg transition duration-300"
    >
      {item.content}
    </div>
  );
};

export default DraggableItem;
