import React, { useState, useCallback, useMemo } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { Alert, AlertDescription } from '../components/ui/alert';
import { Button } from '../components/ui/button';
import { Loader2 } from 'lucide-react';

const StudentGroups = () => {
  interface Student {
    number: number;
    score: number;
  }
  
  interface Group {
    groupNumber: number;
    students: Student[];
    averageScore: number;
    highestScore: number;
    lowestScore: number;
  }
  
  const [groups, setGroups] = useState<Group[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [studentCount, setStudentCount] = useState(0);

  const calculateGroupStats = (students: Student[]) => {
    if (students.length === 0) return { avg: 0, high: 0, low: 0 };
    const scores = students.map(s => s.score);
    return {
      avg: parseFloat((scores.reduce((a, b) => a + b, 0) / students.length).toFixed(1)),
      high: Math.max(...scores),
      low: Math.min(...scores)
    };
  };
  
  const processGroups = useCallback((data: { scores: Student[] }) => {
    if (!Array.isArray(data.scores)) {
      setError('Invalid data format received');
      return [];
    }
    
    if (data.scores.length > 40) {
      setError('Cannot process more than 40 students');
      return [];
    }

    const totalStudents = data.scores.length;
    // Determine optimal number of groups (aim for 4-5 students per group)
    const idealStudentsPerGroup = 5;
    const minGroups = Math.ceil(totalStudents / idealStudentsPerGroup);
    const totalGroups = minGroups;

    // Sort students by score in descending order
    const sortedStudents = [...data.scores].sort((a, b) => b.score - a.score);
    
    // Initialize groups
    const initialGroups: Group[] = Array.from({ length: totalGroups }, (_, index) => ({
      groupNumber: index + 1,
      students: [],
      averageScore: 0,
      highestScore: 0,
      lowestScore: 0
    }));

    // Calculate base size and remainder for uneven distribution
    const baseGroupSize = Math.floor(totalStudents / totalGroups);
    const remainderStudents = totalStudents % totalGroups;

    // First pass: Distribute top and bottom students alternately
    const topHalf = sortedStudents.slice(0, Math.ceil(sortedStudents.length / 2));
    const bottomHalf = sortedStudents.slice(Math.ceil(sortedStudents.length / 2)).reverse();
    
    let topIndex = 0;
    let bottomIndex = 0;
    
    initialGroups.forEach((group, groupIndex) => {
      // Calculate target size for this group
      const targetSize = baseGroupSize + (groupIndex < remainderStudents ? 1 : 0);
      
      while (group.students.length < targetSize) {
        // Add one strong student
        if (topIndex < topHalf.length && group.students.length < targetSize) {
          group.students.push(topHalf[topIndex]);
          topIndex++;
        }
        
        // Add one weaker student
        if (bottomIndex < bottomHalf.length && group.students.length < targetSize) {
          group.students.push(bottomHalf[bottomIndex]);
          bottomIndex++;
        }
      }
    });

    // Second pass: Balance groups by swapping students if it improves overall balance
    let improved = true;
    while (improved) {
      improved = false;
      
      for (let i = 0; i < initialGroups.length; i++) {
        for (let j = i + 1; j < initialGroups.length; j++) {
          const group1 = initialGroups[i];
          const group2 = initialGroups[j];
          
          for (let si = 0; si < group1.students.length; si++) {
            for (let sj = 0; sj < group2.students.length; sj++) {
              // Calculate current averages
              const currentAvg1 = calculateGroupStats(group1.students).avg;
              const currentAvg2 = calculateGroupStats(group2.students).avg;
              const currentDiff = Math.abs(currentAvg1 - currentAvg2);
              
              // Try swap
              const tempStudent1 = group1.students[si];
              const tempStudent2 = group2.students[sj];
              
              const tempGroup1 = [...group1.students];
              const tempGroup2 = [...group2.students];
              
              tempGroup1[si] = tempStudent2;
              tempGroup2[sj] = tempStudent1;
              
              const newAvg1 = calculateGroupStats(tempGroup1).avg;
              const newAvg2 = calculateGroupStats(tempGroup2).avg;
              const newDiff = Math.abs(newAvg1 - newAvg2);
              
              // If swap improves balance, do it
              if (newDiff < currentDiff) {
                group1.students[si] = tempStudent2;
                group2.students[sj] = tempStudent1;
                improved = true;
              }
            }
          }
        }
      }
    }

    // Calculate final statistics
    return initialGroups.map(group => {
      const stats = calculateGroupStats(group.students);
      return {
        ...group,
        averageScore: stats.avg,
        highestScore: stats.high,
        lowestScore: stats.low
      };
    });
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch('https://pretoeic.kantinanplayground.com/api/scores-summary');
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }
      const data = await response.json();
      setStudentCount(data.total_students);
      const processedGroups = processGroups(data);
      setGroups(processedGroups);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  }, [processGroups]);

  const groupCards = useMemo(() => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {groups.map((group) => (
        <div 
          key={group.groupNumber} 
          className="border rounded-lg p-4 bg-white shadow"
        >
          <div className="flex justify-between items-center mb-3">
            <h3 className="text-lg font-bold">
              Group {group.groupNumber} 
              <span className="text-sm font-normal ml-2 text-gray-600">
                ({group.students.length} students)
              </span>
            </h3>
            <div className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
              Avg: {group.averageScore}
            </div>
          </div>
          <div className="space-y-2">
            {group.students.map((student) => (
              <div 
                key={`${group.groupNumber}-${student.number}`}
                className="flex justify-between items-center bg-gray-50 p-2 rounded"
              >
                <span className="text-gray-700">Student #{student.number}</span>
                <div className="flex gap-2 items-center">
                  <span className="font-medium">{student.score}</span>
                  <span className={`text-xs ${
                    student.score === group.lowestScore 
                      ? 'text-red-600' 
                      : student.score === group.highestScore
                        ? 'text-green-600'
                        : 'text-blue-600'
                  }`}>
                    {student.score === group.lowestScore 
                      ? 'Lowest' 
                      : student.score === group.highestScore
                        ? 'Highest'
                        : 'Regular'}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  ), [groups]);

  return (
    <div className="p-4">
      <Card className="w-full max-w-7xl mx-auto">
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle className="flex items-center gap-4">
              <span>Student Groups {studentCount > 0 && `(${studentCount} Students)`}</span>
              <Button 
                onClick={fetchData} 
                disabled={loading}
                className="ml-4"
              >
                {loading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Loading
                  </>
                ) : (
                  'Fetch Data'
                )}
              </Button>
            </CardTitle>
            <span className="text-sm font-normal text-gray-600">
              Groups balanced by average score
            </span>
          </div>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          
          {groups.length === 0 && !loading && !error && (
            <div className="text-center text-gray-500 py-8">
              Click "Fetch Data" to load student groups
            </div>
          )}

          {groupCards}
        </CardContent>
      </Card>
    </div>
  );
};

export default StudentGroups;