import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './card';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend, LabelList } from 'recharts';
import { Clock, Skull, Users, Monitor, Trophy, LineChart, Table, Search, X, Loader2 } from 'lucide-react';
import { AlertDialog, AlertDialogAction, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../ui/alert-dialog";


interface TaskOrder {
  task_description: string;
  task_order?: number;
  frequency?: number;
  order_frequencies?: Record<number, number>;
  stage?: number;
}

interface PlayerDeathHistory {
  death_id: number;
  player_id: string;
  session_id: string;
  stage: number;
  cause_id: number;
  death_time: number;
  created_at: string;
}

const DEATH_CAUSES: Record<number, string> = {
  1: "Restart game",
  2: "Failed to turn off lights in time",
  3: "Failed to lock the door in time",
  4: "Failed to close the curtains in time",
  5: "Failed to turn off the TV in time",
  6: "Failed to call or send SMS to 191 in time",
  7: "Failed to turn off the alert in time",
  8: "Failed to turn off the radio in time",
  9: "Failed to enter the next room before the timer starts",
  10: "Walked too close to the stairs",
  11: "Ran in the room when the hunter was watching",
  12: "Failed to hide before the hunter arrived",
  13: "Failed the quick-time event (heart reaches 0)",
  14: "Failed to enter the closet before the hunter arrived",
  15: "Killed by the red area or moved into an open space (Run out from playing area)",
  16: "Time reaches 00:00 during the run"
};

const getStageNameFromNumber = (stage: number) => {
  switch (stage) {
    case 1: return 'Stage 1-1';
    case 2: return 'Stage 1-2';
    case 3: return 'Stage 2';
    case 4: return 'Stage 3';
    default: return `Stage ${stage}`;
  }
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    payload: {
      name: string;
      value: number;
      percentage: string;
      label: string;
    };
  }>;
  label?: string;
}

// Add type definitions for the processed data
interface ProcessedDataItem {
  name: string;
  value: number;
  percentage: string;
  label: string;
  color: string;
}

interface DeathStageDataItem extends ProcessedDataItem {
  stageName: string;
  stage: number;
  death_count: number;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) return null;

  const data = payload[0].payload;
  return (
    <div className="bg-white p-2 border rounded shadow">
      <p className="text-sm">{`${data.name || label}: ${data.value} (${data.percentage}%)`}</p>
    </div>
  );
};

interface ApiResponse {
  status: boolean;
  data: {
    deathRanking: Array<{
      cause_id: number;
      stage: number;
      occurrence_count: number;
    }>;
    taskOrderFrequency: Array<{
      task_description: string;
      stage: number;
      task_order: number;
      frequency: number;
    }>;
    stageDeathRanking: Array<{
      stage: number;
      death_count: number;
    }>;
    playerDeviceCount: Array<{
      device_type: string;
      device_count: number;
    }>;
    sessionPlayerCountRanking: Array<{
      session_id: string;
      players_in_session: number;
      session_count: number;
    }>;
    averageStageTime: Array<{
      stage: number;
      min_time_taken: number;
      max_time_taken: number;
      average_time_taken: number;
    }>;
    genderDistribution: Array<{
      gender_name: string;
      selection_count: number;
    }>;
  };
}

const LoadingState = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col items-center justify-center space-y-4">
          <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
          <div className="text-lg font-medium text-gray-900">Loading Dashboard Data</div>
          <div className="flex flex-col items-center text-sm text-gray-500">
            <p>Fetching latest statistics...</p>
            <p>This may take a few moments</p>
          </div>
        </div>
        
        {/* Loading skeleton for cards */}
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className="bg-white rounded-lg shadow p-6 animate-pulse">
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
              <div className="space-y-3">
                <div className="h-3 bg-gray-200 rounded"></div>
                <div className="h-3 bg-gray-200 rounded w-5/6"></div>
                <div className="h-3 bg-gray-200 rounded w-4/6"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [showCharts, setShowCharts] = useState(true);
  const [data, setData] = useState<ApiResponse['data'] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [playerId, setPlayerId] = useState('');
  const [isPlayerView, setIsPlayerView] = useState(false);
  const [selectedStage, setSelectedStage] = useState(1);
  const [selectedPlayerAmount, setSelectedPlayerAmount] = useState(1);
  const [selectedDate, setSelectedDate] = useState('');
  const [isDateView, setIsDateView] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const minDate = '2024-11-05';
  const today = new Date().toISOString().split('T')[0];
  const [showNoDataAlert, setShowNoDataAlert] = useState(false);

  
 
  const fetchData = async (playerSpecific: boolean = false) => {
    setLoading(true);
    setError(null);
    
    try {
      let url = `https://densuapi.kantinanplayground.com/get-game-stats`;
      
      // Only add date parameters if we're in date view mode and have both dates
      if (isDateView && startDate && endDate) {
        url += `?start_date=${startDate}&end_date=${endDate}`;
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'x-api-key': '5a1b3b110315e2ae70e0f8ff21fae9642b12f2e7e7974c35c5ca134686316edb',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result: ApiResponse = await response.json();
      
      if (result.status) {
        setData(result.data);
      } else {
        throw new Error('API returned false status');
      }
    } catch (err) {
      console.error('Fetch error:', err);
      setError(err instanceof Error ? err.message : 'Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  // Modified handleDateSearch function
  const handleDateSearch = async () => {
    if (startDate && endDate) {
      setLoading(true);
      setError(null);
      
      try {
        const url = `https://densuapi.kantinanplayground.com/get-game-stats?start_date=${startDate}&end_date=${endDate}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'x-api-key': '5a1b3b110315e2ae70e0f8ff21fae9642b12f2e7e7974c35c5ca134686316edb',
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const result = await response.json();
        
        if (result.status) {
          // Check if there's any meaningful data
          const hasData = result.data && (
            (result.data.deathRanking && result.data.deathRanking.length > 0) ||
            (result.data.taskOrderFrequency && result.data.taskOrderFrequency.length > 0) ||
            (result.data.stageDeathRanking && result.data.stageDeathRanking.length > 0)
          );
  
          if (!hasData) {
            // Show no data alert
            setShowNoDataAlert(true);
            // Fetch default data
            const defaultResponse = await fetch('https://densuapi.kantinanplayground.com/get-game-stats', {
              method: 'GET',
              headers: {
                'x-api-key': '5a1b3b110315e2ae70e0f8ff21fae9642b12f2e7e7974c35c5ca134686316edb',
                'Content-Type': 'application/json',
              },
            });
            
            if (!defaultResponse.ok) {
              throw new Error(`HTTP error! status: ${defaultResponse.status}`);
            }
  
            const defaultResult = await defaultResponse.json();
            
            if (defaultResult.status) {
              setData(defaultResult.data);
              setIsDateView(false);
              setStartDate('');
              setEndDate('');
            }
          } else {
            // Set the date-filtered data
            setData(result.data);
            setIsDateView(true);
          }
        } else {
          throw new Error('API returned false status');
        }
      } catch (err) {
        console.error('Fetch error:', err);
        setError(err instanceof Error ? err.message : 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    }
  };
  
  
  const NoDataAlert = () => (
    <AlertDialog open={showNoDataAlert} onOpenChange={setShowNoDataAlert}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>No Data Available</AlertDialogTitle>
          <AlertDialogDescription>
            No data found for the selected date range.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction onClick={() => setShowNoDataAlert(false)}>
            OK
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
  
  // Modified handleResetView function
  const handleResetView = async () => {
    setIsDateView(false);
    setStartDate('');
    setEndDate('');
    
    // Do a regular fetch without date parameters
    const url = 'https://densuapi.kantinanplayground.com/get-game-stats';
    
    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'x-api-key': '5a1b3b110315e2ae70e0f8ff21fae9642b12f2e7e7974c35c5ca134686316edb',
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      
      if (result.status) {
        setData(result.data);
      } else {
        throw new Error('API returned false status');
      }
    } catch (err) {
      console.error('Fetch error:', err);
      setError(err instanceof Error ? err.message : 'Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    console.log('Component mounted, initiating data fetch');
    fetchData(false);
  }, []);

  const transformTimeData = (stageTimeData: ApiResponse['data']['averageStageTime']) => {
    if (!stageTimeData) return [];

    return stageTimeData.map(stageTime => ({
      stage: getStageNameFromNumber(stageTime.stage),
      min: stageTime.min_time_taken,
      avg: stageTime.average_time_taken,
      max: stageTime.max_time_taken
    }));
  };

  const processTaskOrderFrequency = (tasks: Array<{
    task_description: string;
    stage: number;
    task_order: number;
    frequency: number;
  }> = []) => {
    if (!tasks || tasks.length === 0) return [];
  
    // Group tasks by description and stage
    return Object.values(tasks.reduce((acc, task) => {
      const key = `${task.task_description}-${task.stage}`;
      if (!acc[key]) {
        acc[key] = {
          task_description: task.task_description,
          stage: task.stage,
          task_order: task.task_order,
          frequency: task.frequency,
          order_frequencies: {}
        };
      }
      acc[key].order_frequencies[task.task_order] = task.frequency;
      return acc;
    }, {} as Record<string, {
      task_description: string;
      stage: number;
      task_order: number;
      frequency: number;
      order_frequencies: Record<number, number>;
    }>));
  };

  interface DashboardData {
    deathRanking: Array<{
      cause_id: number;
      stage: number;
      occurrence_count: number;
    }>;
    taskOrderFrequency: Array<{
      task_description: string;
      stage: number;
      task_order: number;
      frequency: number;
    }>;
    stageDeathRanking: Array<{
      stage: number;
      death_count: number;
    }>;
    playerDeviceCount: Array<{
      device_type: string;
      device_count: number;
    }>;
    sessionPlayerCountRanking: Array<{
      session_id: string;
      players_in_session: number;
      session_count: number;
    }>;
    averageStageTime: Array<{
      stage: number;
      min_time_taken: number;
      max_time_taken: number;
      average_time_taken: number;
    }>;
    genderDistribution: Array<{
      gender_name: string;
      selection_count: number;
    }>;
  }
  

  const processDeathStageData = (deathStageData: Array<{ stage: number; death_count: number }>): DeathStageDataItem[] => {
    const total = deathStageData.reduce((acc, data) => acc + data.death_count, 0);
    return deathStageData.map((data): DeathStageDataItem => {
      const stageName = getStageNameFromNumber(data.stage);
      const percentage = ((data.death_count / total) * 100).toFixed(1);
      return {
        stageName,
        stage: data.stage,
        death_count: data.death_count,
        percentage,
        name: stageName,
        value: data.death_count,
        label: `${data.death_count} (${percentage}%)`,
        color: '#ef4444'
      };
    });
  };
  const processSessionData = (sessionData: ApiResponse['data']['sessionPlayerCountRanking']): ProcessedDataItem[] => {
    const total = sessionData.reduce((acc, session) => acc + (session.session_count || 1), 0);

    return sessionData.map((session): ProcessedDataItem => {
      const value = session.session_count || 1;
      const percentage = ((value / total) * 100).toFixed(1);
      const name = `${session.players_in_session} Player${session.players_in_session > 1 ? 's' : ''}`;
      return {
        name,
        value,
        percentage,
        label: `${name}: ${value} (${percentage}%)`,
        color: session.players_in_session === 1 ? "#3b82f6" :
          session.players_in_session === 2 ? "#10b981" :
            session.players_in_session === 3 ? "#f59e0b" : "#ef4444"
      };
    });
  };

  const processGenderData = (data: ApiResponse['data']): ProcessedDataItem[] => {
    if (!data.genderDistribution) return [];

    const total = data.genderDistribution.reduce((acc, item) =>
      acc + item.selection_count, 0);

    return data.genderDistribution.map((item): ProcessedDataItem => {
      const value = item.selection_count;
      const percentage = ((value / total) * 100).toFixed(1);
      return {
        name: item.gender_name,
        value,
        percentage,
        label: `${item.gender_name}: ${value} (${percentage}%)`,
        color: item.gender_name === 'male' ? '#3b82f6' :
          item.gender_name === 'female' ? '#ec4899' : '#9ca3af'
      };
    });
  };

  if (loading) {
    return <LoadingState />;
  }


  // Add error boundary for better error handling
  if (error || !data) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <p className="text-xl text-red-500 mb-4">Error loading dashboard</p>
          <p className="text-gray-600">{error}</p>
          <button 
            onClick={() => fetchData(false)}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  // The rest of the component remains the same...

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-xl">Loading dashboard data...</p>
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-xl text-red-500">{error || 'Failed to load data'}</p>
      </div>
    );
  }

  const sessionData = data.sessionPlayerCountRanking.map(session => ({
    type: `${session.players_in_session} Player${session.players_in_session > 1 ? 's' : ''}`,
    count: session.session_count,
    color: session.players_in_session === 1 ? "#3b82f6" :
      session.players_in_session === 2 ? "#10b981" :
        session.players_in_session === 3 ? "#f59e0b" : "#ef4444"
  }));

  const stageTimeData = data.averageStageTime;
  const deathStageData = data.stageDeathRanking;

  const DeviceDistribution: React.FC<{ data: ApiResponse['data'] }> = ({ data }) => {
    const totalDevices = data.playerDeviceCount.reduce((acc: any, device: { device_count: any; }) => acc + device.device_count, 0);

    return (
      <Card>
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-sm font-medium">Device Distribution</CardTitle>
          <Monitor className="w-4 h-4 text-gray-500" />
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {data.playerDeviceCount.map((device: { device_count: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined; device_type: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => {
              const percentage = (((Number(device.device_count) ?? 0) / totalDevices) * 100).toFixed(1);
              return (
                <div key={index} className="flex flex-col">
                  <p className="text-lg font-bold">{device.device_type}</p>
                  <p className="text-sm text-gray-500">
                    Active devices: {device.device_count} ({percentage}%)
                  </p>
                </div>
              );
            })}
          </div>
        </CardContent>
      </Card>
    );
  };

  const handleStartDateChange = (e: { target: { value: any; }; }) => {
    const selectedDate = e.target.value;
    if (selectedDate < minDate) {
      alert('Start date cannot be before November 5, 2024');
      return;
    }
    if (selectedDate > today) {
      alert('Start date cannot be in the future');
      return;
    }
    if (endDate && selectedDate > endDate) {
      alert('Start date cannot be after end date');
      return;
    }
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (e: { target: { value: any; }; }) => {
    const selectedDate = e.target.value;
    if (selectedDate > today) {
      alert('End date cannot be in the future');
      return;
    }
    if (startDate && selectedDate < startDate) {
      alert('End date cannot be before start date');
      return;
    }
    setEndDate(selectedDate);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
          <NoDataAlert />
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-between mb-8 gap-4">
          <div className="flex items-center">
            <Trophy className="w-8 h-8 mr-3 text-yellow-500" />
            <h1 className="text-3xl font-bold text-gray-800">School of Survival</h1>
          </div>

          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={startDate ? startDate : "Start Date"}
                onFocus={(e) => e.target.type = 'date'}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.target.type = 'text'
                  }
                }}
                onChange={handleStartDateChange}
                min={minDate}
                max={today}
                className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 w-40"
              />

              <span>to</span>

              <input
                type="text"
                value={endDate ? endDate : "End Date"}
                onFocus={(e) => e.target.type = 'date'}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.target.type = 'text'
                  }
                }}
                onChange={handleEndDateChange}
                min={startDate || minDate}
                max={today}
                className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 w-40"
              />
              <button
                onClick={handleDateSearch}
                className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                disabled={!startDate || !endDate}
              >
                <Search className="w-4 h-4" />
                <span>Search Date</span>
              </button>
              {isDateView && (
                <button
                  onClick={handleResetView}
                  className="flex items-center gap-2 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
                >
                  <X className="w-4 h-4" />
                  <span>Reset</span>
                </button>
              )}
            </div>

            <button
              onClick={() => setShowCharts(!showCharts)}
              className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow hover:bg-gray-50"
            >
              {showCharts ? (
                <>
                  <Table className="w-4 h-4" />
                  <span>Show Numbers</span>
                </>
              ) : (
                <>
                  <LineChart className="w-4 h-4" />
                  <span>Show Charts</span>
                </>
              )}
            </button>
          </div>
        </div>

        {isDateView && (
          <div className="mb-4 p-4 bg-blue-50 rounded-lg">
            <p className="text-blue-800">
              Viewing data from{' '}
              <span className="font-bold">
                {new Date(startDate).toLocaleDateString('en-US', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </span>
              {' '}to{' '}
              <span className="font-bold">
                {new Date(endDate).toLocaleDateString('en-US', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </span>
            </p>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <Card className="col-span-2">
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium">
                {isPlayerView ? "Player Fail History" : "Fail Ranking"}
              </CardTitle>
              <Skull className="w-4 h-4 text-gray-500" />
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                {(() => {
                  // Merge fails with same cause_id across all stages
                  const mergedDeaths = data.deathRanking.reduce((acc, death) => {
                    const existingDeath = acc.find(d => d.cause_id === death.cause_id);
                    if (existingDeath) {
                      existingDeath.occurrence_count += death.occurrence_count;
                    } else {
                      acc.push({ ...death });
                    }
                    return acc;
                  }, [] as typeof data.deathRanking);

                  // Sort by occurrence count
                  return mergedDeaths
                    .sort((a, b) => b.occurrence_count - a.occurrence_count)
                    .map((death, index) => (
                      <div key={index} className="flex justify-between items-center p-2 bg-gray-50 rounded">
                        <div className="flex flex-col">
                          <span className="text-sm font-medium">
                            {death.cause_id !== undefined ?
                              DEATH_CAUSES[death.cause_id] :
                              'Unknown cause'}
                          </span>
                        </div>
                        <span className="text-sm font-bold">
                          {death.occurrence_count}
                        </span>
                      </div>
                    ));
                })()}
              </div>
            </CardContent>
          </Card>

          <DeviceDistribution data={data} />



          <Card>
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium">
                Most Fail Stage
              </CardTitle>
              <Skull className="w-4 h-4 text-gray-500" />
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                {(() => {
                  // Calculate total deaths per stage
                  const stageOccurrences: Record<string, number> = data.deathRanking.reduce((acc, death) => {
                    const stage = death.stage.toString();
                    acc[stage] = (acc[stage] || 0) + death.occurrence_count;
                    return acc;
                  }, {} as Record<string, number>);

                  const mostCommonStage = Object.entries(stageOccurrences)
                    .reduce((a, b) => (b[1] > a[1] ? b : a));

                  const totalDeaths = mostCommonStage[1];
                  const stageNumber = mostCommonStage[0];

                  // Get deaths for this stage to calculate percentages
                  const stageDeaths = data.deathRanking
                    .filter(death => death.stage === Number(stageNumber));

                  return (
                    <>
                      <p className="text-lg font-bold">
                        {getStageNameFromNumber(Number(stageNumber))}
                      </p>
                      <p className="text-sm text-gray-500">
                        Total deaths: {totalDeaths}
                      </p>
                      <div className="mt-4">
                        <p className="text-sm font-medium mb-2">Top Fail Causes:</p>
                        {stageDeaths
                          .sort((a, b) => b.occurrence_count - a.occurrence_count)
                          .slice(0, 3)
                          .map((death, index) => (
                            <div key={index} className="text-sm text-gray-600 flex justify-between items-center">
                              <span>{DEATH_CAUSES[death.cause_id]}</span>
                              <div className="text-right">
                                <span className="font-medium">{death.occurrence_count}</span>
                                <span className="text-gray-400 ml-2">
                                  ({((death.occurrence_count / totalDeaths) * 100).toFixed(1)}%)
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  );
                })()}
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium">
                Skin Gender Distribution
              </CardTitle>
              <Users className="w-4 h-4 text-gray-500" />
            </CardHeader>
            <CardContent>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={processGenderData(data)}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      label={({ value, percentage }) => `${value} (${percentage}%)`}
                    >
                      {processGenderData(data).map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Session Types Distribution</CardTitle>
            </CardHeader>
            <CardContent>
              {showCharts ? (
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={processSessionData(data.sessionPlayerCountRanking)}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        label={({ value, percentage }) => `${value} (${percentage}%)`}
                      >
                        {processSessionData(data.sessionPlayerCountRanking).map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <Tooltip content={<CustomTooltip />} />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <div className="space-y-4">
                  {processSessionData(data.sessionPlayerCountRanking).map((item, index) => (
                    <div key={index} className="flex justify-between items-center">
                      <span className="text-sm font-medium">{item.name}</span>
                      <span className="text-lg font-bold">
                        {item.value} ({item.percentage}%)
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </CardContent>
          </Card>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center justify-between">
                <div className="flex items-center">
                  <Clock className="w-4 h-4 mr-2" />
                  Stage Completion Times (seconds)
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-64">
                {data && data.averageStageTime && (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={transformTimeData(data.averageStageTime)} barGap={0}>
                      <XAxis dataKey="stage" />
                      <YAxis label={{ value: 'Seconds', angle: -90, position: 'insideLeft' }} />
                      <Tooltip formatter={(value) => `${value}s`} />
                      <Legend />
                      <Bar dataKey="min" name="Minimum" fill="#10b981">
                        <LabelList dataKey="min" position="top" formatter={(value: any) => `${value}s`} />
                      </Bar>
                      <Bar dataKey="avg" name="Average" fill="#3b82f6">
                        <LabelList dataKey="avg" position="top" formatter={(value: any) => `${value}s`} />
                      </Bar>
                      <Bar dataKey="max" name="Maximum" fill="#ef4444">
                        <LabelList dataKey="max" position="top" formatter={(value: any) => `${value}s`} />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </div>
            </CardContent>
          </Card>


          <Card>
            <CardHeader>
              <CardTitle>Fail per Stage</CardTitle>
            </CardHeader>
            <CardContent>
              {showCharts ? (
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={processDeathStageData(deathStageData)}>
                      <XAxis dataKey="stageName" />
                      <YAxis />
                      <Tooltip content={<CustomTooltip />} />
                      <Bar dataKey="death_count" fill="#ef4444" name="Deaths">
                        <LabelList
                          dataKey="label"
                          position="top"
                          fill="#000000"
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <div className="space-y-4">
                  {processDeathStageData(deathStageData).map((item, index) => (
                    <div key={index} className="flex justify-between items-center">
                      <span className="text-sm font-medium">{item.stageName}</span>
                      <span className="text-lg font-bold">{item.label}</span>
                    </div>
                  ))}
                </div>
              )}
            </CardContent>
          </Card>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

          <Card>
            <CardHeader>
              <CardTitle className="flex items-center justify-between">
                <span>Task Order Frequency</span>
                <select
                  value={selectedStage}
                  onChange={(e) => setSelectedStage(Number(e.target.value))}
                  className="ml-4 px-2 py-1 border rounded"
                >
                  <option value={1}>{getStageNameFromNumber(1)}</option>
                  <option value={2}>{getStageNameFromNumber(2)}</option>
                </select>

              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {processTaskOrderFrequency(data.taskOrderFrequency)
                  .filter(task => task.stage === selectedStage)
                  .map((task, index) => (
                    <div key={index} className="space-y-2">
                      <div className="font-medium">{task.task_description}</div>
                      <div className="grid grid-cols-6 gap-2">
                        {[1, 2, 3, 4, 5, 6].map(order => (
                          <div
                            key={order}
                            className="text-center p-2 rounded"
                            style={{
                              backgroundColor: task.order_frequencies?.[order]
                                ? `rgba(16, 185, 129, ${task.order_frequencies[order] / 300})`
                                : 'rgba(229, 231, 235, 0.5)'
                            }}
                          >
                            <div className="text-xs">Order {order}</div>
                            <div className="font-bold">
                              {task.order_frequencies?.[order] || 0}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;