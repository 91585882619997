import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { Alert, AlertDescription } from '../components/ui/alert';
import { Button } from '../components/ui/button';


// Helper functions for statistics
const calculateStats = (numbers: any[]) => {
    const n = numbers.length;
    if (n === 0) return { min: 0, max: 0, mean: 0, std: 0 };

    const min = Math.min(...numbers);
    const max = Math.max(...numbers);
    const mean = numbers.reduce((a, b) => a + b, 0) / n;
    const variance = numbers.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / n;
    const std = Math.sqrt(variance);

    return {
        min: min.toFixed(2),
        max: max.toFixed(2),
        mean: mean.toFixed(2),
        std: std.toFixed(2)
    };
};

const calculatePValue = (tStat: number, df: number) => {
    const x = Math.abs(tStat);
    const df2 = df / 2;
    const p = 2 * (1 - (1 - Math.exp(-0.717 * x - 0.416 * x * x)));
    return p;
};

interface RoundDetail {
    roundNumber: number;
    attempts: number;
}

interface GameStats {
    gameNumber: number;
    sessions: {
        sessionId: string;
        rounds: RoundDetail[];
    }[];
    averageAttempts: number;
}

interface GameRoundStats {
    gameNumber: number;
    sessions: {
        sessionId: string;
        totalRounds: number;
    }[];
    averageRounds: number;
}
const calculateGameRounds = (logData: { sessions: any[]; }) => {
    if (!logData || !logData.sessions) return [];

    // Initialize array for 10 games
    const gameStats: GameRoundStats[] = Array.from({ length: 10 }, (_, i) => ({
        gameNumber: i + 1,
        sessions: [],
        averageRounds: 0
    }));

    // Process each session
    logData.sessions.forEach(session => {
        session.games.forEach((game: { gameNumber: number; rounds: any[]; }) => {
            const gameIndex = game.gameNumber - 1;
            if (gameIndex >= 0 && gameIndex < 10) {
                // Count number of rounds in this game for this session
                const roundCount = game.rounds.length;
                if (roundCount > 0) {
                    gameStats[gameIndex].sessions.push({
                        sessionId: session.sessionName,
                        totalRounds: roundCount
                    });
                }
            }
        });
    });

    // Calculate average rounds for each game
    gameStats.forEach(game => {
        const totalRounds = game.sessions.reduce((sum, session) => sum + session.totalRounds, 0);
        const sessionCount = game.sessions.length;
        game.averageRounds = sessionCount > 0
            ? Number((totalRounds / sessionCount).toFixed(2))
            : 0;
    });

    return gameStats;
};

const calculateAverageRoundsPerSession = (logData: { sessions: any[]; }): {
    sessionId: string;
    averageRounds: number;
    totalRounds: number;
    totalGames: number;
}[] => {
    if (!logData || !logData.sessions) return [];

    const sessionAverages = logData.sessions.map(session => {
        let totalRounds = 0;
        let totalGames = 0;

        session.games.forEach((game: { rounds: any[]; }) => {
            if (game.rounds && game.rounds.length > 0) {
                totalRounds += game.rounds.length;
                totalGames += 1;
            }
        });

        return {
            sessionId: session.sessionName,
            averageRounds: totalGames > 0 ? totalRounds / totalGames : 0,
            totalRounds,
            totalGames
        };
    });

    return sessionAverages;
};

interface Stats {
    pre: { mean: number };
    post: { mean: number };
    tTest: { meanDiff: number };
}


const calculatePairedTTest = (pre: any[], post: string | any[]) => {
    if (pre.length !== post.length || pre.length === 0) return { t: 0, p: 1, significant: false, df: 0 };

    const differences = pre.map((val, idx) => post[idx] - val);
    const n = differences.length;
    const meanDiff = differences.reduce((a, b) => a + b, 0) / n;
    const squaredDiffs = differences.map(d => Math.pow(d - meanDiff, 2));
    const variance = squaredDiffs.reduce((a, b) => a + b, 0) / (n - 1);
    const standardError = Math.sqrt(variance / n);
    const tStat = meanDiff / standardError;
    const df = n - 1; // degrees of freedom
    const pValue = calculatePValue(tStat, df);

    return {
        t: tStat.toFixed(4),
        meanDiff: meanDiff.toFixed(2),
        p: pValue.toFixed(4),
        significant: pValue < 0.05,
        df: df
    };
};

const calculateGameAttempts = (logData: { sessions: any[]; }) => {
    if (!logData || !logData.sessions) return [];

    // Initialize array for 10 games
    const gameStats = Array.from({ length: 10 }, (_, i) => ({
        gameNumber: i + 1,
        totalAttempts: 0,
        totalRounds: 0,
        averageAttempts: 0,
        roundDetails: [] as { roundNumber: number; attempts: number }[]
    }));

    // Process all sessions
    logData.sessions.forEach(session => {
        session.games.forEach((game: { gameNumber: number; rounds: any[]; }) => {
            const gameIndex = game.gameNumber - 1;
            if (gameIndex >= 0 && gameIndex < 10) {
                game.rounds.forEach(round => {
                    if (round.answers && Array.isArray(round.answers)) {
                        gameStats[gameIndex].totalAttempts += round.answers.length;
                        gameStats[gameIndex].totalRounds += 1;
                        gameStats[gameIndex].roundDetails.push({
                            roundNumber: round.roundNumber,
                            attempts: round.answers.length
                        });
                    }
                });
            }
        });
    });

    // Calculate averages
    gameStats.forEach(game => {
        game.averageAttempts = game.totalRounds > 0
            ? Number((game.totalAttempts / game.totalRounds).toFixed(2))
            : 0;
    });

    return gameStats;
};

// Match pre-test numbers with post-test student numbers
const getMatchedScores = (preScores: any[], postScores: any[], studentNumbers: any[]) => {
    const matchedScores: { pre: any; post: any; }[] = [];

    studentNumbers.forEach(studentNum => {
        const preScore = preScores.find(s => String(s.studentNumber) === String(studentNum));
        if (!preScore) return;

        const postScore = postScores.find(s => String(s.preTestNumber) === String(studentNum));
        if (!postScore) return;

        matchedScores.push({
            pre: preScore.score.correct,
            post: postScore.score.correct
        });
    });

    return matchedScores;
};

const EducationDashboard = () => {
    const [files, setFiles] = useState<{
        hint: File | null,
        pre: File | null,
        post: File | null,
        hintLog: File | null,
        noHintLog: File | null
    }>({
        hint: null,
        pre: null,
        post: null,
        hintLog: null,
        noHintLog: null
    });

    const [data, setData] = useState<{
        hintMapping: { students: { hint_enabled: boolean; student_number: string }[] } | null;
        preTestScores: any[];
        postTestScores: any[];
        hintLog: any;
        noHintLog: any;
    }>({
        hintMapping: null,
        preTestScores: [],
        postTestScores: [],
        hintLog: null,
        noHintLog: null
    });

    const [error, setError] = useState('');
    const [isCalculated, setIsCalculated] = useState(false);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, fileType: string) => {
        const file = event.target.files ? event.target.files[0] : null;
        setFiles(prev => ({ ...prev, [fileType]: file }));
        setIsCalculated(false);
    };

    const validateFiles = () => {
        if (!files.hint || !files.pre || !files.post || !files.hintLog || !files.noHintLog) {
            setError('Please upload all required files');
            return false;
        }
        return true;
    };

    const processFiles = async () => {
        setError('');
        if (!validateFiles()) return;

        try {
            const fileReaders = Object.entries(files).map(([type, file]) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        try {
                            if (e.target && e.target.result) {
                                const content = JSON.parse(e.target.result as string);
                                resolve({ type, content: JSON.parse(e.target.result as string) });
                            } else {
                                reject(`Error reading ${type} file`);
                            }
                        } catch (err) {
                            reject(`Invalid JSON in ${type} file`);
                        }
                    };
                    reader.onerror = () => reject(`Error reading ${type} file`);
                    if (file) {
                        reader.readAsText(file);
                    } else {
                        reject(`File is null for ${type}`);
                    }
                });
            });

            const results = await Promise.all(fileReaders) as { type: string, content: any }[];
            const newData = {
                hintMapping: null,
                preTestScores: [],
                postTestScores: [],
                hintLog: null,
                noHintLog: null
            };

            results.forEach(({ type, content }: { type: string, content: any }) => {
                switch (type) {
                    case 'hint':
                        if (!content.students) throw new Error('Invalid hint mapping file structure');
                        newData.hintMapping = content;
                        break;
                    case 'pre':
                        if (!content.students) throw new Error('Invalid pre-test file structure');
                        newData.preTestScores = content.students;
                        break;
                    case 'post':
                        if (!content.students) throw new Error('Invalid post-test file structure');
                        newData.postTestScores = content.students;
                        break;
                    case 'hintLog':
                        if (!content.sessions) throw new Error('Invalid hint log file structure');
                        newData.hintLog = content;
                        break;
                    case 'noHintLog':
                        if (!content.sessions) throw new Error('Invalid no-hint log file structure');
                        newData.noHintLog = content;
                        break;
                }
            });

            setData(newData);
            setIsCalculated(true);
            setError('');
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message || 'Error processing files');
            } else {
                setError('Error processing files');
            }
            setIsCalculated(false);
        }
    };

    // Get hint and no-hint student numbers
    const getHintGroups = () => {
        if (!data.hintMapping) return { hintEnabled: [], noHintEnabled: [] };

        return {
            hintEnabled: data.hintMapping.students.filter(s => s.hint_enabled).map(s => s.student_number),
            noHintEnabled: data.hintMapping.students.filter(s => !s.hint_enabled).map(s => s.student_number)
        };
    };

    // Calculate statistics only if isCalculated is true
    const { hintEnabled, noHintEnabled } = getHintGroups();
    const hintMatchedScores = isCalculated ? getMatchedScores(data.preTestScores, data.postTestScores, hintEnabled) : [];
    const noHintMatchedScores = isCalculated ? getMatchedScores(data.preTestScores, data.postTestScores, noHintEnabled) : [];

    const hintStats = {
        pre: calculateStats(hintMatchedScores.map(s => s.pre)),
        post: calculateStats(hintMatchedScores.map(s => s.post)),
        tTest: calculatePairedTTest(
            hintMatchedScores.map(s => s.pre),
            hintMatchedScores.map(s => s.post)
        )
    };

    const noHintStats = {
        pre: calculateStats(noHintMatchedScores.map(s => s.pre)),
        post: calculateStats(noHintMatchedScores.map(s => s.post)),
        tTest: calculatePairedTTest(
            noHintMatchedScores.map(s => s.pre),
            noHintMatchedScores.map(s => s.post)
        )
    };

    const chartData = [
        {
            name: 'Pre-Test',
            'Hint Group': Number(hintStats.pre.mean),
            'No-Hint Group': Number(noHintStats.pre.mean)
        },
        {
            name: 'Post-Test',
            'Hint Group': Number(hintStats.post.mean),
            'No-Hint Group': Number(noHintStats.post.mean)
        }
    ];

    return (
        <div className="p-6 max-w-7xl mx-auto">
            <h1 className="text-3xl font-bold mb-6">Education Data Analysis Dashboard</h1>

            <Card className="mb-6">
                <CardHeader>
                    <CardTitle>File Upload</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <label className="block mb-2">Hint Mapping JSON:</label>
                            <input
                                type="file"
                                onChange={(e) => handleFileUpload(e, 'hint')}
                                accept=".json"
                                className="mb-4"
                            />
                            {files.hint && <p className="text-sm text-green-600">✓ {files.hint.name}</p>}
                        </div>
                        <div>
                            <label className="block mb-2">Pre-Test Scores JSON:</label>
                            <input
                                type="file"
                                onChange={(e) => handleFileUpload(e, 'pre')}
                                accept=".json"
                                className="mb-4"
                            />
                            {files.pre && <p className="text-sm text-green-600">✓ {files.pre.name}</p>}
                        </div>
                        <div>
                            <label className="block mb-2">Post-Test Scores JSON:</label>
                            <input
                                type="file"
                                onChange={(e) => handleFileUpload(e, 'post')}
                                accept=".json"
                                className="mb-4"
                            />
                            {files.post && <p className="text-sm text-green-600">✓ {files.post.name}</p>}
                        </div>
                        <div>
                            <label className="block mb-2">Hint Log JSON:</label>
                            <input
                                type="file"
                                onChange={(e) => handleFileUpload(e, 'hintLog')}
                                accept=".json"
                                className="mb-4"
                            />
                            {files.hintLog && <p className="text-sm text-green-600">✓ {files.hintLog.name}</p>}
                        </div>
                        <div>
                            <label className="block mb-2">No-Hint Log JSON:</label>
                            <input
                                type="file"
                                onChange={(e) => handleFileUpload(e, 'noHintLog')}
                                accept=".json"
                                className="mb-4"
                            />
                            {files.noHintLog && <p className="text-sm text-green-600">✓ {files.noHintLog.name}</p>}
                        </div>
                    </div>

                    {error && (
                        <Alert variant="destructive" className="mb-4">
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    )}

                    <Button
                        onClick={processFiles}
                        className="w-full"
                        disabled={!files.hint || !files.pre || !files.post || !files.hintLog || !files.noHintLog}
                    >
                        Process Files and Calculate Statistics
                    </Button>
                </CardContent>
            </Card>

            {isCalculated && (
                <>
                    <div className="grid grid-cols-2 gap-4 mb-6">
                        <Card>
                            <CardHeader>
                                <CardTitle>Hint Group Statistics (n={hintMatchedScores.length})</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <div className="mb-4">
                                    <h3 className="font-semibold">Pre-Test</h3>
                                    <p>Min: {hintStats.pre.min} </p>
                                    <p>Max: {hintStats.pre.max} </p>
                                    <p>Mean: {hintStats.pre.mean} </p>
                                    <p>Std Dev: {hintStats.pre.std}</p>
                                </div>
                                <div className="mb-4">
                                    <h3 className="font-semibold">Post-Test</h3>
                                    <p>Min: {hintStats.post.min} </p>
                                    <p>Max: {hintStats.post.max} </p>
                                    <p>Mean: {hintStats.post.mean} </p>
                                    <p>Std Dev: {hintStats.post.std}</p>
                                </div>
                                <div>
                                    <h3 className="font-semibold">Paired T-Test Results</h3>
                                    <p>t-statistic: {hintStats.tTest.t}</p>
                                    <p>Mean Difference: {hintStats.tTest.meanDiff} points</p>
                                    <p>p-value: {hintStats.tTest.p}</p>
                                    <p className={hintStats.tTest.significant ? "text-green-600 font-semibold" : "text-red-600"}>
                                        {hintStats.tTest.significant
                                            ? "✓ Statistically Significant (p < 0.05)"
                                            : "✗ Not Statistically Significant (p ≥ 0.05)"}
                                    </p>
                                </div>
                            </CardContent>
                        </Card>

                        <Card>
                            <CardHeader>
                                <CardTitle>No-Hint Group Statistics (n={noHintMatchedScores.length})</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <div className="mb-4">
                                    <h3 className="font-semibold">Pre-Test</h3>
                                    <p>Min: {noHintStats.pre.min} </p>
                                    <p>Max: {noHintStats.pre.max} </p>
                                    <p>Mean: {noHintStats.pre.mean} </p>
                                    <p>Std Dev: {noHintStats.pre.std}</p>
                                </div>
                                <div className="mb-4">
                                    <h3 className="font-semibold">Post-Test</h3>
                                    <p>Min: {noHintStats.post.min} </p>
                                    <p>Max: {noHintStats.post.max} </p>
                                    <p>Mean: {noHintStats.post.mean} </p>
                                    <p>Std Dev: {noHintStats.post.std}</p>
                                </div>
                                <div>
                                    <h3 className="font-semibold">Paired T-Test Results</h3>
                                    <p>t-statistic: {noHintStats.tTest.t}</p>
                                    <p>Mean Difference: {noHintStats.tTest.meanDiff} points</p>
                                    <p>p-value: {noHintStats.tTest.p}</p>
                                    <p className={noHintStats.tTest.significant ? "text-green-600 font-semibold" : "text-red-600"}>
                                        {noHintStats.tTest.significant
                                            ? "✓ Statistically Significant (p < 0.05)"
                                            : "✗ Not Statistically Significant (p ≥ 0.05)"}
                                    </p>
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    <Card className="mb-6">
                        <CardHeader>
                            <CardTitle>Score Comparison Chart</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <LineChart width={600} height={300} data={chartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="Hint Group" stroke="#8884d8" />
                                <Line type="monotone" dataKey="No-Hint Group" stroke="#82ca9d" />
                            </LineChart>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader>
                            <CardTitle>Rounds Analysis</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <h3 className="font-semibold mb-4">Hint Group ({data.hintLog?.sessions.length} sessions)</h3>

                                    {/* Session Averages */}
                                    <div className="mb-6 bg-gray-50 p-4 rounded-lg">
                                        <h4 className="font-medium mb-2">Average Rounds per Session:</h4>
                                        {data.hintLog && calculateAverageRoundsPerSession(data.hintLog).map((session, idx) => (
                                            <div key={idx} className="mb-2">
                                                <div className="text-sm">
                                                    {session.sessionId}:
                                                    <span className="font-medium ml-2">
                                                        {session.averageRounds.toFixed(2)} rounds/game
                                                    </span>
                                                </div>
                                                <div className="text-xs text-gray-600">
                                                    Total: {session.totalRounds} rounds in {session.totalGames} games
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>

                                <div>
                                    <h3 className="font-semibold mb-4">No-Hint Group ({data.noHintLog?.sessions.length} sessions)</h3>

                                    {/* Session Averages */}
                                    <div className="mb-6 bg-gray-50 p-4 rounded-lg">
                                        <h4 className="font-medium mb-2">Average Rounds per Session:</h4>
                                        {data.noHintLog && calculateAverageRoundsPerSession(data.noHintLog).map((session, idx) => (
                                            <div key={idx} className="mb-2">
                                                <div className="text-sm">
                                                    {session.sessionId}:
                                                    <span className="font-medium ml-2">
                                                        {session.averageRounds.toFixed(2)} rounds/game
                                                    </span>
                                                </div>
                                                <div className="text-xs text-gray-600">
                                                    Total: {session.totalRounds} rounds in {session.totalGames} games
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader>
                            <CardTitle>Number of Rounds Needed Per Game</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <h3 className="font-semibold mb-4">Hint Group ({data.hintLog?.sessions.length} sessions)</h3>
                                    {data.hintLog && (
                                        <div className="space-y-4">
                                            {calculateGameRounds(data.hintLog).map((game) => (
                                                <div key={game.gameNumber} className="border-b pb-3">
                                                    <div className="flex justify-between items-center mb-2">
                                                        <span className="font-medium">Game {game.gameNumber}</span>
                                                    </div>
                                                    {game.sessions.map((session, idx) => (
                                                        <div key={idx} className="ml-4 text-sm text-gray-600">
                                                            <div>{session.sessionId}: {session.totalRounds} rounds</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <h3 className="font-semibold mb-4">No-Hint Group ({data.noHintLog?.sessions.length} sessions)</h3>
                                    {data.noHintLog && (
                                        <div className="space-y-4">
                                            {calculateGameRounds(data.noHintLog).map((game) => (
                                                <div key={game.gameNumber} className="border-b pb-3">
                                                    <div className="flex justify-between items-center mb-2">
                                                        <span className="font-medium">Game {game.gameNumber}</span>
                                                    </div>
                                                    {game.sessions.map((session, idx) => (
                                                        <div key={idx} className="ml-4 text-sm text-gray-600">
                                                            <div>{session.sessionId}: {session.totalRounds} rounds</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                </>
            )}
        </div>
    );
};

export default EducationDashboard;